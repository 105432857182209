<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h1>Levering</h1>
            </v-col>

            <v-col cols="12" class="pa-1">
              <v-row justify="space-around">
                <v-col lg="6" md="8" sm="12" class="text-center">
                  <h4>Vælg hvor ofte du vil modtage Dryk</h4>
                  <v-select :items="plans" item-value="handle" item-text="name" v-model="selectedPlan" filled label="Vælg levering" v-on:change="changeSelectedPlan"></v-select>
                </v-col>
              </v-row>

              <div v-show="selectedPlan == oneTimePlan">
                <v-row>
                  <v-col cols="12"> Du har valgt et engangskøb. Du kan også vælge et abonnement. Hvis du har valgt et abonnement, kan du altid ændre abonnement indholdet og dato for næste levering. </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">Du kan altid opsige et abonnement uden varsel. Der er ingen bindingsperiode. Det gør du på abonnement siden. </v-col>
                </v-row>
              </div>

              <div v-show="selectedPlan !== oneTimePlan && selectedPlan != null">
                <v-row>
                  <v-col cols="12"> Du har valgt et abonnement, og kan løbende ændre abonnement indholdet og dato for næste levering. </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">Du kan altid opsige dit abonnement uden varsel. Der er ingen bindingsperiode. Det gør du på abonnement siden. </v-col>
                </v-row>
              </div>
              <div v-show="selectedPlan != null">
                <v-row>
                  <v-col cols="12">Vi pakker og sender hverdagene mandag og torsdag. Levering er derefter normalt 1-2 arbejdsdage.</v-col>
                </v-row>
              </div>
              <v-card class="mb-12 pa-0 mt-5" flat v-show="selectedPlan">
                <v-row justify="space-around">
                  <v-col lg="12" md="12" sm="12" class="d-flex justify-center">
                    <div>
                      <h4>Hvor skal det leveres til?</h4>
                      <v-radio-group v-model="deliveryMethod" row flat>
                        <v-radio :label="'GLS Pakkeshop'" value="droppoint"></v-radio>
                        <v-radio :label="'Direkte levering'" value="direct"></v-radio>
                      </v-radio-group></div></v-col
                ></v-row>
                <v-card-actions v-show="deliveryMethod == 'droppoint'">
                  <v-card-text>
                    Indtast post nr og hele vejnavnet for at finde en GLS pakkeshop tættest på dig
                    <v-row class="pa-0">
                      <v-col cols="3">
                        <v-text-field v-model="zip" label="Post nr"></v-text-field>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field v-model="street" label="Vejnavn"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="pa-0">
                      <v-col cols="12" class="pa-0">
                        <v-select :items="dropPoints" item-text="displayName" item-value="id" v-model="selectedDropPointId" v-show="dropPoints" @change="selectDropPoint" filled label="Vælg leveringsted"></v-select>
                      </v-col>
                      <v-col cols="12" class="pa-0 ma-0">
                        <v-row v-if="selectedDropPoint">
                          <v-col cols="6" class="pa-0 ma-0">
                            {{ selectedDropPoint.name }}<br />
                            {{ selectedDropPoint.address }}, {{ selectedDropPoint.zipcode }} {{ selectedDropPoint.city }}
                          </v-col>
                          <v-col cols="6" class="pa-0 ma-0">
                            Åbningstider:<br />
                            <v-col cols="12" class="pa-0 ma-0" v-for="day in selectedDropPoint.opening_hours" :key="day"> {{ day }} </v-col>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card-actions>
                <v-card-actions v-show="deliveryMethod == 'direct'">
                  <v-container>
                    <v-row>
                      <v-col cols="12"><h4>Indtast leveringadresse</h4></v-col>
                      <v-col cols="12"> <v-btn small class="v-btn-green" v-on:click="fillAddress()">Udfyld med din egen adresse</v-btn></v-col>
                      <v-col cols="6">
                        <v-text-field label="Fornavn*" v-model="deliveryAddress.firstName" type="name" required></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Efternavn*" v-model="deliveryAddress.lastName" type="name" required></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field label="Adresse*" v-model="deliveryAddress.address1" type="adress" required></v-text-field>
                        <v-text-field label="Adresse" v-model="deliveryAddress.address2" type="adress"></v-text-field>
                      </v-col>

                      <v-col cols="3">
                        <v-text-field label="Postnr.*" v-model="deliveryAddress.zip" type="number" onKeyPress="if(this.value.length==4) return false;" maxlength="4" required></v-text-field>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field label="By*" v-model="deliveryAddress.city" type="city" required></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field label="Firma" v-model="deliveryAddress.companyName"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea label="Kommentar til fragtfirma. Evt. hvor dine Dryk skal stilles (max. 35 tegn)" :auto-grow="true" :clearable="true" :counter="35" maxlength="35" :filled="true" :flat="true" v-model="deliveryAddress.note" />
                      </v-col>
                      <v-col cols="12">
                        <v-btn dark color="#8DB294" v-on:click="callSaveDeliveryAddress">Gem leveringsadresse</v-btn>
                      </v-col>
                    </v-row>
                    <small>*Skal udfyldes</small>
                  </v-container>
                </v-card-actions>
              </v-card>
              <div v-if="dropPointValid || addressValid" class="grey lighten-5">
                <v-row>
                  <v-col cols="12">
                    <span class="headline font-weight-bold" :v-if="basket != null">Ordre : {{ basket && selectedPlan ? plans.find((plan) => plan.handle == selectedPlan).name : '' }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-simple-table light v-if="showOrder" class="pa-0 black--text font-weight-bold grey lighten-5">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Type</th>
                            <th class="text-left">Antal</th>
                            <th class="text-right">Pris</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in orderlines" :key="item.name">
                            <td class="text-left">{{ item.ordertext }}</td>
                            <td>{{ getQuantity(item) }}</td>
                            <td class="text-right">{{ item.amount | reepayCurrency }}</td>
                          </tr>
                          <tr>
                            <td colspan="2" class="text-left">
                              <strong> Total </strong>
                            </td>

                            <td class="text-right">
                              <strong>{{ invoice.amount | reepayCurrency }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row class="black--text font-weight-bold" v-if="deliveryMethod == 'droppoint' && dropPointValid">
                  <v-col cols="12"> Levering: GLS Pakkeshop, {{ selectedDropPoint.name }}, {{ selectedDropPoint.address }}, {{ selectedDropPoint.zipcode }} {{ selectedDropPoint.city }} </v-col>
                </v-row>
                <v-row class="black--text font-weight-bold pa-2" v-if="deliveryMethod == 'direct' && addressValid">
                  <v-col cols="12">
                    Levering:
                    <span v-if="deliveryAddress.companyName">{{ deliveryAddress.companyName }},</span>
                    {{ deliveryAddress.firstName }} {{ deliveryAddress.lastName }}, {{ deliveryAddress.address1 }}, {{ deliveryAddress.zip }} {{ deliveryAddress.city }}
                  </v-col>
                </v-row>
              </div>
              <v-spacer class="pa-5"></v-spacer>
              <div v-if="dropPointValid || addressValid">
                <v-row>
                  <v-col cols="12" class="d-flex align-center justify-center terms">
                    <v-checkbox v-model="approveTerms" class="align-center justify-center">
                      <template v-slot:label>
                        <div class="align-center justify-center">
                          Godkend
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a target="_blank" href="https://dryk.dk/vilkaar/" @click.stop v-on="on"> betingelserne </a>
                            </template>
                            Læs betingelserne her. Åbner i nyt vindue
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"> <v-btn class="v-btn-orange white--text" v-on:click="createOrder" v-bind:disabled="!approveTerms">Godkend og betal</v-btn> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"> </v-col>
                </v-row>
              </div>
              <div v-if="test">
                <v-btn class="v-btn-orange white--text" v-on:click="showOrderCompleted">showOrderCompleted</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import retryInterceptor from 'axios-retry-interceptor';
import core from '../mixins/core';

import shipment from '../mixins/shipment';
import customerMixin from '../mixins/customer';
import * as Sentry from '@sentry/browser';

retryInterceptor(axios, {
  maxAttempts: 10,
  waitTime: 1000,
});

export default {
  name: 'Basket',
  mixins: [core, shipment, customerMixin],
  data() {
    return {
      plans: [],
      products: [],
      freights: [],
      directFreights: [],
      discountPacks: [],
      selectedFreight: null,
      orderStarted: false,

      dialogVisible: false,
      approveTerms: false,
      paymentLink: null,
      step: 1,
      oneTimePlan: 'onetime-plan-fa3af',
      selectedPlan: null, // 'plan-2d2fb',
      selectedPlanDetails: null,
      waitForPayment: false,
      deliveryType: 'onetime',
      paymentCompleted: false,
      token: null,
      orderPreview: null,
      invoice: {
        amount: 0,
      },
      orderlines: null,
      basket: null,
      showOrder: false,
      customer: false,
      deliveryMethod: null,
      zip: null,
      street: null,
      voucher: null,
      validatedVoucher: null,
      dropPoints: null,
      selectedDropPoint: null,
      selectedDropPointId: null,
      deliveryMessage: '',
      deliveryAddress: {
        companyName: null,
        cvr: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        zip: null,
        city: null,
        att: null,
        phone: null,
        email: null,
        note: null,
      },
      dropPointValid: false,
      addressValid: false,
      weekday: new Array(7),
      reePayModal: null,
      subscription: null,
    };
  },

  created: function () {},
  mounted: function () {
    if (!this.$store.getters.loggedIn) this.$router.push('/');

    this.customer = this.$store.getters.customer;
    this.plans = this.$store.getters.plans;
    this.selectedPlan = null; // 'onetime-plan-fa3af';
    this.directFreights = this.$store.getters.directFreights;
    this.freights = this.$store.getters.freights;
    this.setDeliveryMessage();

    this.showOrder = false;
    this.orderPreview = null;
    this.invoice = {
      amount: 0,
    };
    this.orderlines = null;

    this.$on('showOrderCompleted', this.showOrderCompleted);
    this.$root.$on('previewOrder', () => {});
    this.doPreviewOrder();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
  methods: {
    fillAddress: function () {
      this.deliveryAddress = {
        companyName: this.customer.company,
        cvr: this.customer.cvr,
        firstName: this.customer.first_name,
        lastName: this.customer.last_name,
        address1: this.customer.address,
        address2: this.customer.address2,
        zip: this.customer.postal_code,
        city: this.customer.city,
      };
      this.callSaveDeliveryAddress();
    },
    changeSelectedPlan: function (value) {
      console.log(value);
      this.$store.commit('setSelectedPlan', value);
    },
    changeDeliveryType: function (value) {
      console.log(value);
      //      if (value == 'onetime') this.selectedPlan = this.coreData.onetimePlans[0].handle;
      //  else this.selectedPlan = 'plan-2d2fb';
    },
    setDeliveryMessage() {
      var today = new Date().getDay();
      if (today >= 5 || today < 1) this.deliveryMessage = 'Din levering bliver hentet mandag og leveret tirsdag';
      else this.deliveryMessage = 'Din levering bliver hentet torsdag og leveret fredag';
    },
    selectDropPoint() {

      this.selectedDropPoint = this.dropPoints.find((x) => x.id === this.selectedDropPointId);
    },
    getDay(day) {
      switch (day) {
        case 0:
          return 'Mandag';
        case 1:
          return 'Tirsdag';
        case 2:
          return 'Onsdag';
        case 3:
          return 'Torsdag';
        case 4:
          return 'Fredag';
        case 5:
          return 'Lørdag';
        case 6:
          return 'Søndag';

        default:
          return '';
      }
    },
    updateDropPoints(zip, street) {
      if (zip != null && zip.length == 4) {
        console.log(zip);
        this.getdropPointsFromAddress(zip, street).then((dropPoints) => {
          this.dropPoints = dropPoints;
          this.selectedDropPointId = this.dropPoints[0].id;
          this.dropPoints.forEach(function (dropPoint) {
            dropPoint.displayName = dropPoint.name + ', ' + dropPoint.address + ', ' + dropPoint.zipcode + ' ' + dropPoint.city;
          });
          this.selectDropPoint();
        });
      }
    },
    callSaveDeliveryAddress() {
      this.tracking('SaveDeliveryAddress');

      this.saveDeliveryAddress(this.customer.handle, this.deliveryAddress).then((_) => _);
      this.validateDelivery();
      this.$root.$emit('onMessage', 'Adressen er nu gemt');
    },
    getQuantity(item) {
      if (item.ordertext.includes('Fragt') || item.ordertext.includes('Vild natur')) return item.quantity + ' stk.';
      if (item.liters == 0) return item.quantity;
      if (item.origin_handle.includes('coupon')) return 'Rabat';
      else return item.quantity + ' stk. Drykbox' + (item.quantity == 1 ? '' : 'e');
    },
    showOrderCompleted() {
      this.tracking('OrderCompleted');
      Sentry.captureMessage('showOrderCompleted', 'debug');
      this.paymentCompleted = true;
      this.step = 3;
      localStorage.removeItem('subscriptionHandle');
      this.$store.commit('setOrderDetails', {
        deliveryMessage: this.deliveryMessage,
        deliveryMethod: this.deliveryMethod,
        selectedDropPoint: this.selectedDropPoint,
        addressValid: this.addressValid,
        deliveryAddress: this.deliveryAddress,
        dropPointValid: this.dropPointValid,
      });
      this.$root.$emit('onClearBasket');
      setTimeout(() => {
        this.getSubscriptions(this.$store.getters.customer.handle).then(() => {});
        this.getInvoices(this.$store.getters.customer.handle).then(() => {});
        this.$router.push('/ordre-bekraeftelse/' + this.$store.getters.orderSubscription.handle);
      }, 1000);
    },
    startPayment() {
      this.tracking('StartPayment');
      Sentry.captureMessage('StartPayment', 'info');

      this.waitForPayment = true;

      /*eslint no-undef: 1*/
      var handler = reepaytoken.configure({
        key: this.test ? 'pub_bcf1e38bc7dbd8dacc94d6ac72a8af86' : 'pub_e88ddeed4b06760a437b8c9df9c938c6', // Prod
        language: 'da',
        recurring: true,
        token: (result) => {
          Sentry.setExtra('data', JSON.stringify(result, undefined, 2));
          Sentry.captureMessage('PaymentCompleted', 'info');
          localStorage.setItem('_', JSON.stringify(result));
          this.token = result.token;
          console.log(this.token);
          this.createOrder();
        },
        ready: function () {},
        close: () => {
          this.step = 2;
          this.$emit('onMessage', 'Køb afbrudt');
          Sentry.captureMessage('Køb afbrut', 'info');
        },
      });
      // Open modal on open button click

      //event.preventDefault();
      handler.open();
      /*eslint no-undef: 0*/
    },
    createOrder() {
      if (this.orderStarted) return;
      this.orderStarted = true;
      this.tracking('CreateOrder');
      var msg = {
        message: 'onCreateOrder',
        basket: this.basket,
        token: this.token,
        voucher: this.$store.getters.voucher,
      };
      Sentry.setExtra('data', JSON.stringify(msg, undefined, 2));
      Sentry.captureMessage('onCreateOrder', 'info');

      this.prepareOrder(this.basket, this.token, this.$store.getters.voucher).then((subscription) => {
        console.log(subscription);
        this.$store.commit('setOrderSubscription', subscription);
        if (subscription.state == 'pending') {
          this.createSubscriptionChargeSession(subscription.handle).then((session) => {
            this.reePayModal = new Reepay.ModalSubscription(session.id);
            globalThis.vue = this;

            this.reePayModal.addEventHandler(Reepay.Event.Accept, function (data) {
              globalThis.vue.reePayModal = null;
              globalThis.vue.tracking('OrderCompleted');
              globalThis.vue.showOrderCompleted();
              globalThis.vue.orderStarted = false;
              console.log('Success', data);
            });
            this.reePayModal.addEventHandler(Reepay.Event.Error, function (data) {
              globalThis.vue.paymentFailed(globalThis.vue.$store.getters.orderSubscription.handle, data);
              globalThis.vue.orderStarted = false;
              console.log('Error', data);
            });
            this.reePayModal.addEventHandler(Reepay.Event.Cancel, function (data) {
              globalThis.vue.paymentFailed(globalThis.vue.$store.getters.orderSubscription.handle, data);
              globalThis.vue.orderStarted = false;
              console.log('Cancel', data);
            });
            this.reePayModal.addEventHandler(Reepay.Event.Close, function (data) {
              globalThis.vue.paymentFailed(globalThis.vue.$store.getters.orderSubscription.handle, data);
              globalThis.vue.orderStarted = false;
              console.log('Close', data);
            });

            console.log(session.id);

            /*eslint no-undef: 1*/

            //    globalThis.vue.reePayModal.show(session.id);
            console.log(this.reePayModal);

            /*eslint no-undef: 0*/
          });
        }
      });
    },
    paymentFailed(subscriptionhandle) {
      try {
        if (this.reePayModal != null) {
          this.reePayModal.destroy();
          this.reePayModal = null;
        }
      } catch (err) {
        console.log(JSON.stringify(err));
        Sentry.setExtra('data', JSON.stringify(err, undefined, 2));
        Sentry.captureMessage('paymentFailed', 'error');
      }
      if (!this.paymentCompleted) this.$router.push('/ordre-afbrudt/' + subscriptionhandle).catch(() => {});
    },
    doUpdateBasket() {
      this.$store.commit('setBasket', this.basket);
      this.doPreviewOrder();
    },
    doPreviewOrder() {
      this.customer = this.$store.getters.customer;
      this.basket = this.$store.getters.basket;

      var msg = {
        message: 'previewOrder',
        basket: this.basket,
        token: this.token,
        voucher: this.$store.getters.voucher,
      };

      Sentry.setExtra('data', JSON.stringify(msg, undefined, 2));
      Sentry.captureMessage('previewOrder', 'info');

      this.previewOrder(this.basket, this.token, this.$store.getters.voucher).then((subscription) => {
        if (subscription.invoices != null) {
          this.orderPreview = subscription;
          this.invoice = subscription.invoices[0];
          this.orderlines = this.invoice.order_lines;
          this.showOrder = true;
        } else {
          Sentry.setExtra('data', JSON.stringify(subscription, undefined, 2));
          Sentry.captureMessage('Invoice in subscription is null', 'debug');
        }
      });
    },
    validateDelivery() {
      this.addressValid = this.deliveryMethod == 'direct' && this.deliveryAddress.firstName != null && this.deliveryAddress.lastName != null && this.deliveryAddress.address1 != null && this.deliveryAddress.zip != null && this.deliveryAddress.city != null;
      this.dropPointValid = this.deliveryMethod == 'droppoint' && this.selectedDropPoint != null;
      this.basket.deliveryMethod = this.deliveryMethod;
      this.$store.commit('setDeliveryMethod', this.deliveryMethod);
      this.doPreviewOrder();
    },
    cancel: function () {
      if (this.step == 3) {
        this.$emit('onClearBasket');
        this.$emit('onCancel');
      } else {
        this.step = 1;

        this.$emit('onCancel');
      }
    },
  },
  updated: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
    });
  },
  watch: {
    paymentLink: function () {},
    zip: function (zip) {
      this.updateDropPoints(zip, this.street);
    },
    street: function (street) {
      this.updateDropPoints(this.zip, street);
    },
    deliveryMethod: function () {
      this.validateDelivery();
    },
    selectedDropPoint: function () {
      localStorage.setItem('selectedDropPoint', JSON.stringify(this.selectedDropPoint));
      this.saveDropPoint(this.customer.handle, this.selectedDropPoint).then((_) => _);
      this.validateDelivery();
    },
    voucher: function () {
      return axios
        .get(this.baseFunctionUrl + 'ValidateCoupon/' + this.voucher)
        .then((result) => {
          console.log(result);
          this.validatedVoucher = this.voucher;
          this.doPreviewOrder();
        })
        .catch(() => {
          this.validatedVoucher = null;
          this.doPreviewOrder();
        });
    },
  },
};
</script>
