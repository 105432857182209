import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import core from './mixins/core';
import axios from 'axios';
import retryInterceptor from 'axios-retry-interceptor';
import VueAxios from 'vue-axios';
import "./stylus/main.styl";
import * as Sentry from "@sentry/vue";
import {
  BrowserTracing
} from "@sentry/tracing";

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Sentry.init({
  Vue,
  dsn: 'https://8214ee07293948609d4a7e0849214e0c@o426705.ingest.sentry.io/5369829',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "shop.dryk.dk", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


retryInterceptor(axios, {
  maxAttempts: 10,
  waitTime: 1000
});

const moment = require('moment');
require('moment/locale/es');

Vue.use(require('vue-moment'), {
  moment
})

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  },
  theme: {
    dark: true
  }
})

Vue.use(VueAxios, axios)

Vue.config.productionTip = false;
/*Vue.mixin({
  methods: {
    getActivePlans: mixin.getActivePlans
  }
});*/

export const bus = new Vue();

new Vue({
  mixins: [core],
  router,
  axios,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')