<template>
  <v-container v-if="orderDetails">
    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <h1>Ordrebekræftelse</h1>
        <h2>Tusinde tak for din ordre. Betalingen gennemført for abonnement {{ subscriptionId }}</h2>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <p>Din ordre er nu sendt til vores lager, som er klar til at pakke</p>
        <p>Du skal være opmærksom på, at selvom din ordre er pakket, så henter vores vognmand kun varer hos os mandag og torsdag for at mindste vores CO2 aftryk.</p>
        <p>{{ orderDetails.deliveryMessage }} til</p>

        <p v-if="orderDetails.deliveryMethod == 'droppoint' && orderDetails.dropPointValid">
          Pakkeshop:
          <br />
          {{ orderDetails.selectedDropPoint.name }}
          <br />
          {{ orderDetails.selectedDropPoint.address_1 }}
          <br />
          {{ orderDetails.selectedDropPoint.zip }} {{ orderDetails.selectedDropPoint.city }}
        </p>
        <p class="headline" v-if="orderDetails.deliveryMethod == 'direct' && orderDetails.addressValid">
          <span v-if="orderDetails.deliveryAddress.companyName">
            {{ orderDetails.deliveryAddress.companyName }}
            <br />
          </span>
          {{ orderDetails.deliveryAddress.firstName }} {{ orderDetails.deliveryAddress.lastName }}
          <br />
          {{ orderDetails.deliveryAddress.address1 }}
          <br />
          {{ orderDetails.deliveryAddress.zip }} {{ orderDetails.deliveryAddress.city }}
        </p>

        <p>
          <a href="https://dk.trustpilot.com/review/dryk.dk" target="_blank" class="trustpilot"><img alt="" title="Trustpilot Dryk" src="/media/trustpilot.jpg" width="350" /></a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import customer from '../mixins/customer';
export default {
  name: 'OrderConfirmation',
  mixins: [customer],
  data() {
    return {
      subscription: null,
      subscriptionId: null,
      orderDetails: null,
    };
  },
  mounted: function () {
    this.subscriptionId = this.$route.params.id;
    console.log(this.subscriptionId);
    this.getSubscription(this.subscriptionId).then((res) => {
      this.subscription = res.data;
      this.orderDetails = this.$store.getters.orderDetails;
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
};
</script>
