import axios from 'axios';
import retryInterceptor from 'axios-retry-interceptor';
import core from "./core";

retryInterceptor(axios, {
    maxAttempts: 5,
    waitTime: 1000
});
export default {
    mixins: [core],
    methods: {

        getdropPoints: function (zip) {
            return axios
                .get(this.baseFunctionUrl + "ShipMondo/GetDropPointsFromZip/" + zip)
                .then(function (dropPoints) {
                    return dropPoints.data;
                });
        },
        getdropPointsFromAddress: function (zip, address) {
            return axios
                .get(this.baseFunctionUrl + "ShipMondo/GetDropPointsFromAddress/" + zip + "/" + address)
                .then(function (dropPoints) {
                    return dropPoints.data;
                });
        },
        saveDropPoint: function (handle, dropPoint) {
            return axios
                .post(this.baseFunctionUrl + "SetDropPoint/" + handle, dropPoint)
                .then(function (res) {
                    return res.data;
                });
        },
        saveDeliveryAddress: function (handle, address) {
            return axios
                .post(this.baseFunctionUrl + "SetDeliveryAddress/" + handle, address)
                .then(function (res) {
                    return res.data;
                });
        }

    }
}