<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h1>Indkøbskurv</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center" v-show="showOrder == false">
              <v-progress-circular :size="80" color="#8DB294" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
          <div v-show="showOrder">
            <v-row>
              <v-col cols="12" class="text-center">
                <v-simple-table light v-if="showOrder">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Produkt</th>

                        <th class="text-right">Antal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in products.filter((p) => p.count > 0)" :key="item.name">
                        <td class="text-left">{{ item.name }}</td>
                        <td class="text-right">
                          <v-btn fab x-small v-on:click="decrease(item)" class="mr-3 v-btn-green"><v-icon>mdi-minus</v-icon></v-btn> {{ item.count }} <v-btn fab x-small v-on:click="increase(item)" class="ml-3 v-btn-green"><v-icon>mdi-plus</v-icon></v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left"></td>
                        <td class="text-right">
                          <v-btn dark color="#8DB294" small to="/">Tilføj mere Dryk</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center"> <v-btn dark color="#8DB294" small v-on:click="showVoucher = !showVoucher">Jeg har en rabatkode</v-btn> </v-col>
            </v-row>

            <v-row v-if="showVoucher">
              <v-col cols="12">Hvis du har en rabatkode, så indtast den her</v-col>
              <v-col cols="12">
                <v-text-field v-model="voucher" label="Rabatkode"></v-text-field>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn dark class="v-btn-green" v-on:click="checkVoucher">Indløs rabatkode</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center mt-10">
                <h1>Din ordre</h1>
                <v-btn dark class="v-btn-green" v-show="basketModified" small right v-on:click="recalculateOrder">Opdater din ordre</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-simple-table light v-if="showOrder">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Produkt</th>

                        <th class="text-right">Pris</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in orderlines" :key="item.name">
                        <td class="text-left">{{ getQuantity(item) }} {{ item.ordertext }}</td>
                        <td class="text-right">{{ item.amount | reepayCurrency }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">
                          <strong>Total</strong>
                        </td>

                        <td class="text-right">
                          <strong>{{ invoice.amount | reepayCurrency }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center mt-10">
                <v-btn dark class="v-btn-orange" to="/checkout">Videre til levering</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import retryInterceptor from 'axios-retry-interceptor';
import core from '../mixins/core';

import shop from '../mixins/shop';
import shipment from '../mixins/shipment';
import customerMixin from '../mixins/customer';
import * as Sentry from '@sentry/browser';

retryInterceptor(axios, {
  maxAttempts: 10,
  waitTime: 1000,
});

export default {
  name: 'Basket',
  mixins: [core, shipment, customerMixin, shop],
  data() {
    return {
      dialogVisible: false,
      basketModified: false,
      approveTerms: false,
      paymentLink: null,
      showVoucher: false,
      step: 1,
      selectedPlan: 'onetime-plan-fa3af',
      selectedPlanDetails: null,
      waitForPayment: false,
      deliveryType: 'onetime',
      paymentCompleted: false,
      token: null,
      orderPreview: null,
      invoice: {
        amount: 0,
      },
      orderlines: null,
      basket: null,
      products: null,
      showOrder: false,
      customer: false,
      deliveryMethod: null,
      zip: null,
      street: null,
      voucher: null,
      validatedVoucher: null,
      dropPoints: null,
      selectedDropPoint: null,
      selectedDropPointId: null,
      deliveryMessage: '',
      deliveryAddress: {
        companyName: null,
        cvr: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        zip: null,
        city: null,
        att: null,
        phone: null,
        email: null,
        note: null,
      },
      dropPointValid: false,
      addressValid: false,
      weekday: new Array(7),
    };
  },

  created: function () {},
  mounted: function () {
    if (!this.$store.getters.loggedIn) this.$router.push('/');

    this.products = this.$store.getters.products;
    this.customer = this.$store.getters.customer;

    this.setDeliveryMessage();

    this.showOrder = false;
    this.orderPreview = null;
    this.invoice = {
      amount: 0,
    };
    this.orderlines = null;

    this.$on('showOrderCompleted', this.showOrderCompleted);

    this.$nextTick(() => {
      this.doPreviewOrder();
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
  methods: {
    increase(product) {
      if (product.count < 10) product.count++;
      this.doUpdateBasket();
      this.basketModified = true;
    },
    decrease(product) {
      if (product.count > 0) product.count--;
      if (product.count == 0) {
        this.basket = this.basket.filter((p) => p.name != product.name);
      }
      this.doUpdateBasket();
      this.basketModified = true;
    },
    setDeliveryMessage() {
      var today = new Date().getDay();
      if (today >= 5 || today < 1) this.deliveryMessage = 'Din pakke bliver hentet mandag og leveret tirsdag';
      else this.deliveryMessage = 'Din pakke bliver hentet torsdag og leveret fredag';
    },
    selectDropPoint() {
      this.selectedDropPoint = this.dropPoints.find((x) => x.drop_point_id === this.selectedDropPointId);
    },
    getDay(day) {
      switch (day) {
        case 0:
          return 'Mandag';
        case 1:
          return 'Tirsdag';
        case 2:
          return 'Onsdag';
        case 3:
          return 'Torsdag';
        case 4:
          return 'Fredag';
        case 5:
          return 'Lørdag';
        case 6:
          return 'Søndag';

        default:
          return '';
      }
    },
    updateDropPoints(zip, street) {
      if (zip.length == 4) {
        console.log(zip);
        this.getdropPointsFromAddress(zip, street).then((dropPoints) => {
          this.dropPoints = dropPoints;
          this.selectedDropPointId = this.dropPoints[0].drop_point_id;
          this.dropPoints.forEach(function (dropPoint) {
            dropPoint.displayName = dropPoint.name + ', ' + dropPoint.address_1 + ', ' + dropPoint.zip + ' ' + dropPoint.city;
          });
          this.selectDropPoint();
        });
      }
    },
    callSaveDeliveryAddress() {
      this.tracking('SaveDeliveryAddress');

      this.saveDeliveryAddress(this.customer.handle, this.deliveryAddress).then((_) => _);
      this.validateDelivery();
    },
    getQuantity(item) {
      if (item.ordertext.includes('Fragt') || item.ordertext.includes('Vild natur')) return item.quantity + ' stk.';
      if (item.liters == 0) return item.quantity;
      if (item.origin_handle.includes('coupon')) return 'Rabat';
      else return item.quantity + ' stk. Drykbox med ';
    },
    showOrderCompleted() {
      this.tracking('OrderCompleted');
      Sentry.captureMessage('showOrderCompleted', 'debug');
      this.paymentCompleted = true;
      this.step = 3;
      localStorage.removeItem('subscriptionHandle');
      this.$emit('onClearBasket');
      setTimeout(() => {
        this.getSubscriptions(this.$store.getters.customer.handle).then(() => {});
        this.getInvoices(this.$store.getters.customer.handle).then(() => {});
      }, 1000);
    },

    onCreateOrder() {
      this.tracking('CreateOrder');
      var msg = {
        message: 'onCreateOrder',
        basket: this.basket,
        token: this.token,
        voucher: this.validatedVoucher,
      };

      Sentry.setExtra('data', JSON.stringify(msg, undefined, 2));
      Sentry.captureMessage('onCreateOrder', 'info');

      this.sendOrder(this.basket, this.token, this.validatedVoucher).then((subscription) => {
        console.log(subscription);
        this.tracking('OrderCompleted');
        this.$emit('showOrderCompleted');
        localStorage.setItem('subscriptionHandle', subscription.handle);
      });
    },
    doUpdateBasket() {
      this.$store.commit('setProducts', this.products);
      this.calculate();
      this.$root.$emit('onBasketChange');
    },
    checkVoucher() {
      axios
        .get(this.baseFunctionUrl + 'ValidateCoupon/' + this.voucher)
        .then((result) => {
          console.log(result);
          if (result.state !== 'active') {
            this.$store.commit('setVoucher', this.voucher);
            this.$root.$emit('onMessage', 'Rabatkode godkendt');
            this.doPreviewOrder();
          } else {
            this.$root.$emit('onMessage', 'Rabatkode ikke gyldig');
          }
          this.validatedVoucher = this.voucher;
        })
        .catch(() => {
          this.$root.$emit('onMessage', 'Rabatkode ikke gyldig');
          this.validatedVoucher = null;
          this.$store.commit('setVoucher', null);
          this.doPreviewOrder();
        });

      this.$store.commit('setBasket', this.basket);
      this.calculate();
      this.$root.$emit('onBasketChange');
    },
    recalculateOrder() {
      this.doPreviewOrder();
      this.$root.$emit('onMessage', 'Ordre opdateret');
    },
    doPreviewOrder() {
      this.basketModified = false;
      this.doUpdateBasket();
      this.calculate();
      this.basket = this.$store.getters.basket;
      var msg = {
        message: 'previewOrder',
        basket: this.basket,
        token: this.token,
        voucher: this.$store.getters.voucher,
      };

      this.previewOrder(this.basket, this.token, this.$store.getters.voucher).then((subscription) => {
        if (subscription.invoices != null) {
          this.orderPreview = subscription;
          this.invoice = subscription.invoices[0];
          this.orderlines = this.invoice.order_lines;
          this.showOrder = true;
        } else {
          Sentry.setExtra('data', JSON.stringify(msg, undefined, 2));
          Sentry.captureMessage('previewOrder', 'error');
          Sentry.setExtra('data', JSON.stringify(subscription, undefined, 2));
          Sentry.captureMessage('Invoice in subscription is null', 'error');
        }
      });
    },
    validateDelivery() {
      this.addressValid = this.deliveryMethod == 'direct' && this.deliveryAddress.firstName != null && this.deliveryAddress.lastName != null && this.deliveryAddress.address1 != null && this.deliveryAddress.zip != null && this.deliveryAddress.city != null;
      this.dropPointValid = this.deliveryMethod == 'droppoint' && this.selectedDropPoint != null;
      this.basket.deliveryMethod = this.deliveryMethod;
      this.doPreviewOrder();
    },
    cancel: function () {
      if (this.step == 3) {
        this.$emit('onClearBasket');
        this.$emit('onCancel');
      } else {
        this.step = 1;

        this.$emit('onCancel');
      }
    },
  },
  updated: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
    });
  },
  watch: {
    paymentLink: function () {},
    zip: function (zip) {
      this.updateDropPoints(zip, this.street);
    },
    street: function (street) {
      this.updateDropPoints(this.zip, street);
    },
    deliveryMethod: function () {
      this.validateDelivery();
    },
    selectedDropPoint: function () {
      localStorage.setItem('selectedDropPoint', JSON.stringify(this.selectedDropPoint));
      this.saveDropPoint(this.customer.handle, this.selectedDropPoint).then((_) => _);
      this.validateDelivery();
    },
  },
};
</script>
