<template>
  <div>
    <v-form>
      <v-container>
        <v-row justify="center">
          <v-col lg="10" md="10" sm="12">
            <h1>Profil</h1>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-card flat>
              <v-row v-if="customer != null">
                <v-col cols="12">
                  <v-text-field label="Email*" v-model="customer.email" type="email" required></v-text-field>
                  <v-text-field label="Kodeord*" v-model="customer.password" type="password" required></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field label="Fornavn*" v-model="customer.first_name" type="name" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Efternavn*" v-model="customer.last_name" type="name" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Adresse*" v-model="customer.address" type="adress" required></v-text-field>
                  <v-text-field label="Adresse" v-model="customer.address2" type="adress"></v-text-field>
                  <v-text-field label="Mobil*" v-model="customer.phone" type="mobile" onKeyPress="if(this.value.length==8) return false;" maxlength="8" required></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field label="postnr*" v-model="customer.postal_code" type="number" onKeyPress="if(this.value.length==4) return false;" maxlength="4" required></v-text-field>
                </v-col>
                <v-col cols="9">
                  <v-text-field label="By*" v-model="customer.city" type="city" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Firma" v-model="customer.company" type></v-text-field>
                  <v-text-field label="CVR" v-model="customer.vat" type></v-text-field>
                </v-col>
                <v-col cols="12"> <v-btn dark color="#8DB294" v-on:click="doUpdateCustomer()">Gem profil</v-btn></v-col>
                <v-col cols="12"><v-btn dark color="#8DB294" v-on:click="addB2CTag()">Tilmeld til DRYK's Nyhedsbrev</v-btn> </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import customerMixin from '../mixins/customer';
import core from '../mixins/core';
import shipment from '../mixins/shipment';

export default {
  name: 'MyPage',
  mixins: [core, shipment, customerMixin],
  props: ['basket'],
  data() {
    return {
      tab: null,

      customer: null,

      drawer: null,
      mypageNavigation: 'profile',
    };
  },

  mounted: function () {
    this.subscriptions = this.$store.getters.subscriptions;

    this.customer = this.$store.getters.customer;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setSubscriptions') {
        this.subscriptions = state.subscriptions;
      }
      if (mutation.type === 'setInvoices') {
        this.invoices = state.invoices;
      }
      if (mutation.type === 'setCustomer') {
        this.customer = state.customer;
      }
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },

  methods: {
    doUpdateCustomer() {
      this.updateCustomer(this.customer).then((res) => {
        this.$store.commit('setCustomer', res);

        this.$root.$emit('onMessage', 'Profil opdateret');
      });
    },
    addB2CTag() {
      this.addTagToMailChimpUser(this.customer.email, 'B2C').then(() => {
        this.$root.$emit('onMessage', "Du er nu tilføjet til DRYK's nyhedsbrev");
        this.addMailChimpUser(this.customer.email, this.customer.first_name, this.customer.last_name).then(() => {});
      });
    },
    cancel: function () {
      this.$emit('onCancel');
    },
  },
};
</script>
