<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-row>
            <v-col cols="12">
              <h1>Fakturaoversigt</h1>
            </v-col>
          </v-row>
          <v-row v-if="invoices.length == 0">
            <v-col cols="12">
              <h1>Endnu ingen fakturaer</h1>
            </v-col>
          </v-row>
          <v-col cols="12" v-if="invoices.length != 0">
            <v-row>
              <v-col cols="12">
                <v-simple-table light>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="100">Faktura nr</th>
                        <th class="text-left" width="100">Abonnement nr</th>
                        <th class="text-left">Dato</th>
                        <!-- <th class="text-left">Status</th> -->
                        <th class="text-left">Indhold</th>
                        <th width="120" class="text-left">Beløb</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="invoice in invoices" :key="invoice.Handle">
                        <td>{{ invoice.Number }}</td>
                        <td>
                          <v-btn class="v-btn-green" text :to="'/abonnement/' + invoice.Subscription">{{ invoice.Subscription }}</v-btn>
                        </td>
                        <td width="120">{{ invoice.Created | moment('DD-MM-YYYY') }}</td>
                        <!--  <td>{{ invoice.state }}</td>-->
                        <td>
                          <p v-for="orderline in invoice.order_lines" :key="orderline.Id">{{ orderline.Ordertext }}{{ orderline.Ordertext.includes('Fragt') ? '' : ' (' + orderline.Quantity + ' Drykboxe)' }}</p>
                        </td>
                        <td>{{ invoice.Amount | reepayCurrency }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
          <v-card-text>
            <a style="color:#fff;" v-bind:href="'mailto:support@dryk.dk'">Spørgsmål til dit abonnement, skriv til support@dryk.dk</a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import customerMixin from '../mixins/customer';
import core from '../mixins/core';
import shipment from '../mixins/shipment';

export default {
  name: 'MyInvoices',
  mixins: [core, shipment, customerMixin],
  props: ['basket'],
  data() {
    return {
      invoices: [],

      invoiceDetails: false,
    };
  },
  watch: {},
  mounted: function() {
    if (!this.$store.getters.loggedIn) this.$router.push('/');

    this.invoices = this.$store.getters.invoices;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },

  methods: {},
};
</script>
