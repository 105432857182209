<template>
  <div>
    <v-form>
      <v-container>
        <v-row justify="center">
          <v-col lg="10" md="10" sm="12">
            <h1>Abonnement</h1>
            <h3>{{ subscription.handle }}</h3>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col lg="10" md="10" sm="12">
            <v-simple-table light>
              <template v-slot:default>
                <tbody class="text-left">
                  <tr>
                    <td>
                      <h3>Status</h3>
                    </td>
                    <td class="text-right">
                      <h3>{{ subscription | subscriptionState }}</h3>
                      <h3 v-if="activeSubscription && subscription.payment_method_added">{{ activeSubscription && subscription.payment_method_added ? 'Aktivt abonnement' : 'Ikke aktivt abonnement' }}</h3>

                      Abonnement oprettet: {{ $moment(new Date(subscription.created)).format('DD-MM-YYYY') }}
                    </td>
                  </tr>
                  <tr v-if="activeSubscription">
                    <td>
                      <h3>Abonnement</h3>
                    </td>
                    <td class="text-right pb-3">
                      <h3>
                        {{ subscription.description }}<br />
                        Næste abonnement fornyelse {{ nextRenewalDateText }}<br />
                      </h3>
                      <v-menu v-model="menu" :close-on-content-click="false" max-width="290" :disabled="!activeSubscription">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small class="v-btn-green" v-bind="attrs" v-on="on"
                            ><v-icon dark left> mdi-calendar </v-icon>
                            Ændre dato
                          </v-btn>
                        </template>
                        <v-date-picker v-model="nextRenewalDate" :min="tomorrow" locale="da-dk">
                          <v-spacer></v-spacer>
                          <v-btn text class="v-btn-green" @click="menu = false"> Fortryd </v-btn>
                          <v-btn text class="v-btn-green" @click="save"> Gem </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </td>
                  </tr>
                  <tr v-if="activeSubscription">
                    <td>
                      <h3>Levering</h3>
                    </td>
                    <td class="text-right pb-3">Vi pakker og sender hverdagene mandag eller torsdag, efter fornyelsen. Levering er derefter normalt 1-2 arbejdsdage.</td>
                  </tr>

                  <tr>
                    <td>
                      <h3>Betalingskort</h3>
                    </td>
                    <td class="text-right">
                      <h3><v-btn class="v-btn-green" small target="_blank" :href="subscription.hosted_page_links.payment_info">Tilføj/ændre kreditkort</v-btn></h3>
                    </td>
                  </tr>

                  <tr v-if="activeSubscription"></tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row justify="center" class="pt-10">
          <v-col lg="10" md="10" sm="12">
            <h2>Abonnement indhold</h2>
            <p v-if="activeSubscription">
              Du kan kun slette produkter.<br />
              Hvis du vil ændre antal, så skal du slette produktet helt, og efterfølgende tilføje det med det nye antal
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col lg="10" md="10" sm="12">
            <v-simple-table light>
              <template v-slot:default>
                <thead>
                  <tr class="text-left">
                    <th>Vare</th>
                    <th>Antal</th>
                    <th class="text-right">Pris pr. stk</th>
                    <th class="text-right">Total pris</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="addon in addons" :key="addon.handle" class="text-left">
                    <td>{{ addon | productTitle }}</td>
                    <td>
                      {{ addon | productQuantity }}
                      <v-btn text v-if="addon.add_on.type == 'quantity' && activeSubscription" @click="removeAddon(addon)">
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                    <td class="text-right">
                      {{ addon.amount | reepayCurrency }}
                    </td>
                    <td class="text-right">
                      {{ (addon.quantity == 0 ? addon.amount : addon.amount * addon.quantity) | reepayCurrency }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="3" class="text-left">
                      <h3>Total</h3>
                    </th>
                    <th class="text-right">
                      <h3>
                        {{ total | reepayCurrency }}
                      </h3>
                    </th>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <div v-if="activeSubscription">
          <v-row justify="center" class="pt-10">
            <v-col lg="10" md="10" sm="12">
              <h2>Tilføj produkt til abonnement</h2>
              <p>Vælg produkt og antal for at tilføje.</p>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col lg="10" md="10" sm="12">
              <form>
                <v-container fluid>
                  <v-row align="center">
                    <v-col class="d-flexs" cols="12" sm="12" md="6">
                      <v-select v-model="selectedProduct" :items="products" item-text="name" item-value="handle" filled label="Vælg produkt at tilføje" required></v-select>
                    </v-col>
                    <v-col class="d-flexs" cols="12" sm="12" md="6">
                      <v-text-field v-model="selectedProductQuantity" label="Vælg antal at tilføje" type="number" placeholder="Antal" required filled></v-text-field>
                    </v-col>
                    <v-col class="d-flexs" cols="12" sm="12" md="12">
                      <v-btn :disabled="!readyToAddProduct" @click="addProduct(selectedProduct, selectedProductQuantity)"> Tilføj til abonnement </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </form>
            </v-col>
          </v-row>

          <v-row justify="center" v-if="activeSubscription">
            <v-col lg="10" md="10" sm="12"><v-btn class="v-btn-orange white--text" @click="dialog = true">Opsig abonnement </v-btn> </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>

    <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="400">
      <v-card class="mx-auto">
        <v-list-item three-line>
          <v-list-item-content>
            Er du sikker på du vil stoppe dit abonnement ?

            <v-list-item-subtitle>Du kan altid bare vælge at udskyde næste levering</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn class="v-btn-green" @click="dialog = false"> Fortryd </v-btn>
          <v-btn class="v-btn-orange" @click="cancelSubscriptionAction"> Ja, opsig abonnement </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <pre v-if="debug">
              {{ subscription.subscription_changes }}
              <hr />
              {{ subscription }}
              <hr />
              {{ addons }}
              <hr />
            </pre>
  </div>
</template>

<script>
import core from '../mixins/core';
import customer from '../mixins/customer';
import shipment from '../mixins/shipment';

export default {
  name: 'MySubscription',
  mixins: [core, shipment, customer],
  props: ['basket'],
  data() {
    return {
      debug: false,
      dialog: false,
      menu: false,
      numberRules: [(v) => !!v || 'Tal mere end 0 påkrævet', (v) => (v && v.length < 1) || 'Name must be less than 10 characters'],
      activeSubscription: false,
      pendingSubscription: false,

      subscriptionId: null,
      subscription: null,
      addons: null,
      products: null,
      plans: null,
      total: 0,
      subscriptionUpdatePayload: {
        remove_add_ons: [],
        add_ons: [],
      },
      payload: {
        remove_add_ons: [],
        add_ons: [],
      },
      selectedProduct: null,
      selectedProductQuantity: null,
      readyToAddProduct: false,
      selectedProductName: null,
      nextRenewalDate: null,
      nextRenewalDateText: null,

      tomorrow: this.$moment(new Date()).add(1, 'days').format('YYYY-MM-DD'), // .toString(), //.addDays(1)).format('YYYY-MM-DD HH:MM:SS'),
    };
  },
  mounted: function () {
    if (!this.$store.getters.loggedIn) this.$router.push('/');

    this.loadSubscription();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
  filters: {
    productTitle(product) {
      if (product.handle.startsWith('product-dn-jordlod')) return product.add_on.name;
      if (product.handle.startsWith('product-')) return product.add_on.name + ' Drykbox';

      return product.add_on.name;
    },
    productQuantity(product) {
      if (product.quantity == 0) return '1';
      if (product.handle.startsWith('product-dn-jordlod')) return product.quantity + ' m2';
      if (product.handle.startsWith('product-') || product.handle.startsWith('mix-product-')) return product.quantity + ' kasser';

      return product.quantity;
    },
  },
  watch: {
    selectedProductQuantity: function () {
      this.readyToAddProduct = this.selectedProductQuantity > 0 && this.selectedProductQuantity < 20 && this.selectedProduct != null;
    },
    selectedProduct: function () {
      this.readyToAddProduct = this.selectedProductQuantity > 0 && this.selectedProductQuantity < 20 && this.selectedProduct != null;
    },
  },
  methods: {
    addProduct(selectedProductHandle, selectedProductQuantity) {
      if (selectedProductQuantity <= 0) {
        this.$root.$emit('onMessage', 'Husk antal');
        return;
      }
      // var product = this.products.filter((p) => p.handle == selectedProductHandle)[0];
      if (this.subscriptionUpdatePayload.add_ons.filter((p) => p.handle == selectedProductHandle).length == 0) {
        this.subscriptionUpdatePayload.add_ons.push({
          handle: selectedProductHandle,
          quantity: selectedProductQuantity,
          add_on: selectedProductHandle,
        });
        this.updateSubscription();
      }
    },
    removeAddon(product) {
      if (this.subscriptionUpdatePayload.remove_add_ons.filter((p) => p.handle == product.add_on.handle).length == 0) {
        this.subscriptionUpdatePayload.remove_add_ons.push({
          name: product.add_on.name,
          handle: product.handle,
        });
        this.updateSubscription();
      }
    },
    cancelSubscriptionChangeAction() {
      this.cancelSubscriptionChange(this.subscriptionId).then(() => {
        this.dialog = false;
        this.loadSubscription();
        this.$root.$emit('onMessage', 'Din abonnement ændring er nu annuleret. Bekræftelse sendt til din email');
      });
    },
    cancelSubscriptionAction() {
      this.cancelSubscription(this.subscriptionId).then(() => {
        this.dialog = false;
        this.$root.$emit('onMessage', 'Din abonnement  er nu annuleret. Bekræftelse sendt til din email');
        this.$router.push('/abonnement');
      });
    },
    updateSubscription() {
      this.subscriptionUpdatePayload.remove_add_ons.forEach((p) => {
        this.payload.remove_add_ons.push(p.handle);
      });

      this.subscriptionUpdatePayload.add_ons.forEach((p) => {
        this.payload.add_ons.push(p);
      });

      this.updateSubscriptionAddons(this.subscriptionId, this.payload).then(() => {
        this.$root.$emit('onMessage', ' Dit abonnement er nu opdateret. Bekræftelse sendt til din email');
        this.subscriptionUpdatePayload.remove_add_ons = [];
        this.subscriptionUpdatePayload.add_ons = [];
        this.payload.remove_add_ons = [];
        this.payload.add_ons = [];
        this.selectedProduct = null;
        this.selectedProductQuantity = null;

        this.loadSubscription();
      });
    },
    save() {
      this.menu = false;
      this.setSubscriptionRenewalDate(this.subscriptionId, this.nextRenewalDate).then(() => {
        this.$root.$emit('onMessage', 'Leveringsdato er ændret. Bekræftelse sendt til din email');
        this.loadSubscription();
      });
    },
    loadSubscription() {
      this.plans = this.$store.getters.plans;

      this.products = this.$store.getters.products;
      this.subscriptionId = this.$route.params.id;
      this.getSubscription(this.subscriptionId).then((res) => {
        this.subscription = res.data;
        this.nextRenewalDate = this.$moment(new Date(this.subscription.next_period_start)).format('YYYY-MM-DD');
        this.nextRenewalDateText = this.$moment(new Date(this.subscription.next_period_start)).format('DD-MM-YYYY');
        this.activeSubscription = this.subscription.state == 'active';
        this.pendingSubscription = this.subscription.state == 'pending';

        var plan = this.plans.filter((p) => p.handle == this.subscription.plan);
        this.subscription.description = plan[0].name;
        this.getSubscriptionAddons(this.subscriptionId).then((res) => {
          this.addons = res.data;
          this.total = 0;
          this.addons.forEach((product) => {
            if (product.quantity == 0) this.total = this.total + product.amount;
            else this.total = this.total + product.quantity * product.amount;
          });
        });
      });
    },
  },
};
</script>
