<template>
  <v-container>
    <v-row justify="center">
      <v-col lg="8" md="8" sm="12">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="app-login">
          <v-card flat>
            <v-card-title>
              <v-toolbar flat>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Email*" v-model="email" :rules="emailRules" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Kodeord*" v-model="password" :rules="passwordRules" type="password" required></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <small>*Skal udfyldes</small>
            </v-card-actions>
            <v-card-actions>
              <v-btn color="#8DB294" v-on:click="login" :disabled="!valid" class="v-btn-green">Login</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn small v-on:click="reset" class="v-btn-green">Nulstil kodeord</v-btn>
              <v-btn small v-on:click="newCustomer" class="v-btn-green">Opret kunde</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import customer from '../mixins/customer';
import core from '../mixins/core';

export default {
  name: 'Login',
  mixins: [customer, core],

  data() {
    return {
      valid: false,
      lazy: false,
      email: '',
      password: '',
      passwordRules: [(v) => !!v || 'Kodeord er påkrævet'],
      emailRules: [(v) => !!v || 'Email påkrævet', (v) => /.+@.+\..+/.test(v) || 'Email skal være gyldig'],
    };
  },
  mounted: function () {
    this.email = localStorage.getItem('email');
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    cancel: function () {
      this.$emit('onCancel');
    },
    newCustomer: function () {
      // this.$emit("onNewCustomer");
      this.$router.push('/opret-kunde');
    },
    reset: function () {
      if (this.email.length > 0 && this.email.includes('.') && this.email.includes('@')) {
        this.resetPassword(this.email).then((result) => {
          if (result.status == 400) {
            this.tracking('ResetPasswordWrongEmail');
            this.$root.$emit('onMessage', 'Emailen ' + this.email + ' eksisterer ikke i vores system', true);
          } else {
            this.tracking('ResetPasswordSent');
            this.$root.$emit('onMessage', 'Email sendt med nulstil link til ' + this.email);
          }
        });
      } else {
        this.$root.$emit('onMessage', 'For at nulstille din kode skal du skrive en gyldig email i email feltet', true);
      }
    },
    login: function () {
      this.tracking('Login');
      this.customerLogin(this.email, this.password).then((res) => {
        if (res == true) {
          this.getCustomer(this.email)
            .then((res) => {
              this.tracking('LoginCompleted');
              this.$root.$emit('onLoginCompleted', res);
            })
            .then(() => {
              this.getSubscriptions(this.$store.getters.customer.handle).then(() => {});
              this.getInvoices(this.$store.getters.customer.handle).then(() => {});
            });
        } else {
          this.tracking('LoginFailed');
          this.$root.$emit('onLoginFailed');
        }
      });
    },
  },
};
</script>
