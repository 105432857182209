<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <h1>404 - siden findes ikke</h1>
      </v-col>
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <h2>Men skynd dig at købe noget Dryk</h2>
      </v-col>
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <v-btn dark class="v-btn-orange" to="/">Køb Dryk</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: '404',
};
</script>
