import axios from 'axios';
import retryInterceptor from 'axios-retry-interceptor';

var testFunction = "https://drykfunctions.azurewebsites.net/api/";
var prodFunction = "https://drykfunctions.azurewebsites.net/api/";

retryInterceptor(axios, {
    maxAttempts: 10,
    waitTime: 1000,
});
export default {
    data: function () {
        return {

            buildVersion: "2.67",
            headers: {
                Authorization: "cHJpdl85OWFlMDY1MjUwMmRjYzJkMDc1MzYxZDk3YjJmMjRjNQ",
                token: "Sf2eIeDGZihGSEgh7hv5"
            },

            localhost: false,
            test: false,
            plans: [],
            coreData: null,
            currentCustomer: null,
            publicPath: process.env.BASE_URL,
            baseFunctionUrl: this.localhost ? "http://localhost:7071/api/" : this.test ? testFunction : prodFunction, // "https://functions.dryk.dk/api/"
        }
    },
    filters: {
        cleanCurrency(value) {
            if (value == null || value == undefined) return "";
            value = value.toFixed(2);
            return value.replace('.', ',');
        },

        formatCurrency(value) {
            if (value == null || value == undefined) return "";
            return (value).toLocaleString('da-DK', {
                style: 'currency',
                currency: 'DKR'
            });
        },
        reepayCurrency(value) {

            return (value / 100).toLocaleString('da-DK', {
                style: 'currency',
                currency: 'DKR'
            });

        },
        subscriptionState(subscription) {
            if (subscription.is_cancelled == true) return 'Annuleret';
            if (subscription.state == 'active' && subscription.renewing) return 'Aktivt';
            if (subscription.state == 'expired') return 'Udløbet';

            if (!subscription.payment_method_added) return 'Midlertidigt stoppet, Kreditkort mangler';
            return 'Stoppet';
        },
    },

    created: function () {
        this.baseFunctionUrl = this.localhost ? "http://localhost:7071/api/" : this.test ? testFunction : prodFunction,
            console.log(this.baseFunctionUrl);
        axios.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = this.headers.Authorization
                config.headers['token'] = this.headers.token;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    },

    methods: {

        tracking: function (code) {
            code = '/' + code;
            // https://matteo-gabriele.gitbook.io/vue-gtag/v/master/methods/events
            // page(code);
            /*eslint no-undef: 1*/
            fbq('track', code);
            /*eslint no-undef: 0*/
        },

    }
};