import axios from 'axios';
import retryInterceptor from 'axios-retry-interceptor';
import core from "./core";
import customer from "./customer";

retryInterceptor(axios, {
    maxAttempts: 10,
    waitTime: 1000,
});
export default {
    mixins: [core, customer],
    data() {
        return {
            showResetPassword: false,
        };
    },
    methods: {
        checkResetPasswordAction: function () {
            if (this.$route.query.action == "resetpassword") {
                localStorage.setItem("email", this.$route.query.email);
                localStorage.setItem("resetToken", this.$route.query.token);
                this.showResetPassword = true;
                this.$root.$emit('onPasswordReset');

            }
        },
        addVoucher: function () {

            return axios.post(this.baseFunctionUrl + "AddCustomerVoucher/" + localStorage.customerHandle, localStorage.voucher).then(() => {
                return console.log('voucher persisted');
            });
        },
        checkVoucherAction: function () {

            if (this.$route.query.action == "voucher") {
                localStorage.setItem("voucher", this.$route.query.code);
                localStorage.setItem("email", this.$route.query.email);

                if (localStorage.getItem("customerHandle") == null) {
                    this.getCustomerHandle(this.$route.query.email).then((res) => {
                        localStorage.setItem("customerHandle", res);
                    }).then(() => {
                        this.addVoucher();
                    })
                } else
                    this.addVoucher();
            }
        },
        addTag: function () {

            return axios.post(this.baseFunctionUrl + "AddCustomerTag/" + localStorage.customerHandle, localStorage.tag).then(() => {
                return console.log('tag persisted');
            });
        },

        checkTagAction: function () {

            if (this.$route.query.action == "tag") {
                localStorage.setItem("tag", this.$route.query.code);
                localStorage.setItem("email", this.$route.query.email);

                if (localStorage.getItem("customerHandle") == null) {
                    this.getCustomerHandle(this.$route.query.email).then((res) => {
                        localStorage.setItem("customerHandle", res);
                    }).then(() => {
                        this.addTag();
                    })
                } else
                    this.addTag();
            }
        },
        addCampaign: function () {

            return axios.post(this.baseFunctionUrl + "AddCustomerCampaign/" + localStorage.customerHandle, localStorage.campaign).then(() => {
                return console.log('campaign persisted');
            });
        },
        checkCampaignAction: function () {

            if (this.$route.query.action == "campaign") {
                localStorage.setItem("campaign", this.$route.query.code);
                localStorage.setItem("email", this.$route.query.email);

                if (localStorage.getItem("customerHandle") == null) {
                    this.getCustomerHandle(this.$route.query.email).then((res) => {
                        localStorage.setItem("customerHandle", res);
                    }).then(() => {
                        this.addCampaign();
                    })
                } else
                    this.addCampaign();
            }
        },
        checkActivateAccountAction: function () {
            if (this.$route.query.action == "activateaccount") {
                localStorage.setItem("email", this.$route.query.email);


                var payload = {
                    email: this.$route.query.email,
                    verificationToken: encodeURIComponent(this.$route.query.token)
                };

                return axios.post(this.baseFunctionUrl + "VerifyCustomer", payload).then((res) => {

                    if (res.data.Value == decodeURIComponent(payload.verificationToken)) {
                        this.showActivateAccount = true;
                        this.$router.push("/");
                        return true;
                    } else
                        return true;
                });

            }
        },
        checkActions: function () {
            if (this.$route.path != "/") {
                console.log(this.$route.query);
            }
            if (this.$route.query.action == null)
                return true;

            this.checkResetPasswordAction()
            if (!this.showResetPassword) {
                this.checkActivateAccountAction();
                this.checkTagAction();
                this.checkVoucherAction();
                this.checkCampaignAction();

            }

            return true;
        },

    }
}