<template>
  <div>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Levering</h1>
        <p>Hvis du ændrer adressen eller skifter pakkeshop, så vil det påvirke alle fremtidige leveringer</p>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col cols="12">
        <h3>Direkte levering</h3>
        <p>Adressen til direkte levering bruges kun hvis du har et abonnement som har tilkøbt direkte levering</p>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card flat>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-text-field label="Firma" v-model="customer.meta.deliveryAddress.companyName" type="name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Fornavn*" v-model="customer.meta.deliveryAddress.firstName" type="name" required></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Efternavn*" v-model="customer.meta.deliveryAddress.lastName" type="name" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Adresse*" v-model="customer.meta.deliveryAddress.address1" type="adress" required></v-text-field>
                <v-text-field label="Adresse" v-model="customer.meta.deliveryAddress.address2" type="adress"></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field label="postnr*" v-model="customer.meta.deliveryAddress.zip" type="number" onKeyPress="if(this.value.length==4) return false;" maxlength="4" required></v-text-field>
              </v-col>
              <v-col cols="9">
                <v-text-field label="By*" v-model="customer.meta.deliveryAddress.city" type="city" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Mobil" v-model="customer.meta.deliveryAddress.phone" type="mobile" onKeyPress="if(this.value.length==8) return false;" maxlength="8"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn dark color="#8DB294" v-on:click="doUpdateCustomer()">Gem</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col cols="12">
        <h3>Levering til Pakkeshop</h3>
        <p>Du kan nemt vælge en ny pakkeshop at få leveret Dryk til.<br />Hvis du bor i en stor by, så er det vigtigt at indtaste både post nr og vej for at finde en pakkeshop tættest på vejen</p>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card flat>
          <v-row>
            <v-col cols="12" class="pt-10">
              <h4>Indtast post nr og vej for at finde en pakkeshop tættest på</h4>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="zip" label="Indtast post nr" type="number" onKeyPress="if(this.value.length==4) return false;" maxlength="4" required></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-text-field v-model="street" label="Indtast vej" required></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col cols="12" class="pa-0">
              <v-select :items="dropPoints" item-text="displayName" item-value="drop_point_id" v-model="selectedDropPointId" v-show="dropPoints" @change="selectDropPoint" filled label="Vælg leveringsted"></v-select>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <v-list v-if="selectedDropPoint" class="pa-0 ma-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-headline v-html="selectedDropPoint.name"></v-list-item-headline>
                    <v-list-item-subtitle>{{ selectedDropPoint.address_1 }}, {{ selectedDropPoint.zip }} {{ selectedDropPoint.city }}<br /><br /></v-list-item-subtitle>
                    <v-list-item-headline>Åbningstider:</v-list-item-headline>
                    <v-list-item-subtitle v-for="day in selectedDropPoint.opening_hours" :key="day.day">{{ getDay(day.day) }} {{ day.opens_at }}-{{ day.closes_at }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-10">
              <h3>Valgt pakkeshop</h3>
            </v-col>
          </v-row>
          <v-row v-if="customer.meta.dropPoint">
            <v-col cols="12">
              <v-text-field label="Fragtfirma" v-model="customer.meta.dropPoint.carrier_code" readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Pakkeshop" v-model="customer.meta.dropPoint.name" type="name" readonly></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Adresse*" v-model="customer.meta.dropPoint.address_1" type="adress" readonly></v-text-field>
              <v-text-field label="Adresse" v-model="customer.meta.dropPoint.address_2" type="adress" readonly></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field label="postnr*" v-model="customer.meta.dropPoint.zip" readonly></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-text-field label="By*" v-model="customer.meta.dropPoint.city" type="city" readonly></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn dark color="#8DB294" v-on:click="doUpdateCustomer()">Gem</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import customerMixin from '../mixins/customer';
import core from '../mixins/core';
import shipment from '../mixins/shipment';

export default {
  name: 'MyShipmentSettings',
  mixins: [core, shipment, customerMixin],
  props: ['basket'],
  data() {
    return {
      customer: null,
      zip: null,
      street: null,
      dropPoints: null,
      selectedDropPoint: null,
      selectedDropPointId: null,
      deliveryMessage: null,
      deliveryTab: null,
    };
  },
  watch: {
    paymentLink: function () {},
    zip: function (zip) {
      this.updateDropPoints(zip, this.street);
    },
    street: function (street) {
      this.updateDropPoints(this.zip, street);
    },
    selectedDropPoint: function () {
      localStorage.setItem('selectedDropPoint', JSON.stringify(this.selectedDropPoint));
      this.saveDropPoint(this.customer.handle, this.selectedDropPoint)
        .then((_) => _)
        .then(() => {
          this.getCustomer(this.customer.email).then(() => {
            this.customer = this.$store.getters.customer;
          });
        });
    },
  },
  mounted: function () {
    if (!this.$store.getters.loggedIn) this.$router.push('/');

    this.customer = this.$store.getters.customer;

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },

  methods: {
    doUpdateCustomer() {
      this.updateCustomer(this.customer).then((res) => {
        this.$store.commit('setCustomer', res);

        this.$emit('onMessage', 'Profil opdateret');
      });
    },
    cancel: function () {
      this.$emit('onCancel');
    },

    setDeliveryMessage() {
      var today = new Date().getDay();
      if (today >= 5 || today < 1) this.deliveryMessage = 'Din pakke bliver hentet mandag og leveret tirsdag';
      else this.deliveryMessage = 'Din pakke bliver hentet torsdag og leveret fredag';
    },
    selectDropPoint() {
      this.selectedDropPoint = this.dropPoints.find((x) => x.drop_point_id === this.selectedDropPointId);
    },
    getDay(day) {
      switch (day) {
        case 0:
          return 'Mandag';
        case 1:
          return 'Tirsdag';
        case 2:
          return 'Onsdag';
        case 3:
          return 'Torsdag';
        case 4:
          return 'Fredag';
        case 5:
          return 'Lørdag';
        case 6:
          return 'Søndag';

        default:
          return '';
      }
    },

    updateDropPoints(zip, street) {
      if (zip.length == 4) {
        console.log(zip);
        this.getdropPointsFromAddress(zip, street).then((dropPoints) => {
          this.dropPoints = dropPoints;
          this.selectedDropPointId = this.dropPoints[0].drop_point_id;
          this.dropPoints.forEach(function (dropPoint) {
            dropPoint.displayName = dropPoint.name + ', ' + dropPoint.address_1 + ', ' + dropPoint.zip + ' ' + dropPoint.city;
          });
          this.selectDropPoint();
        });
      }
    },
  },
};
</script>
