<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12">
          <span class="headline">Signup</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field label="Email*" v-model="email" type="email" required></v-text-field>
          <v-text-field label="Kodeord*" v-model="password" type="password" required></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field label="Fornavn*" v-model="firstname" type="name" required></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field label="Efternavn*" v-model="lastname" type="name" required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Mobil*" v-model="mobile" type="mobile" onKeyPress="if(this.value.length==8) return false;" maxlength="8" required></v-text-field>

          <v-text-field label="Adresse*" v-model="address" type="adress" required></v-text-field>
          <v-text-field label="Adresse" v-model="address2" type="adress"></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field label="postnr*" v-model="zip" type="number" onKeyPress="if(this.value.length==4) return false;" maxlength="4" required></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="By*" v-model="city" type="city" required></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field label="Firma" v-model="company" type></v-text-field>
          <v-text-field label="CVR" v-model="cvr" type></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn dark color="#8DB294" v-on:click="onCreateCustomer">Opret</v-btn>
          <v-btn dark color="#8DB294" v-on:click="cancel">Fortryd</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <small>*Skal udfyldes</small>
  </v-form>
</template>

<script>
import customer from '../mixins/customer';

export default {
  name: 'Signup',
  mixins: [customer],

  data() {
    return {
      debug: true,
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      mobile: '',
      address: '',
      address2: '',
      zip: '',
      city: '',
      company: '',
      cvr: '',
    };
  },

  mounted: function() {},
  methods: {
    cancel: function() {
      this.$emit('onCancel');
    },
  },
};
</script>
