<template>
  <div>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12">
            <span class="headline">Opret kunde</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Email*" v-model="email" type="email" required></v-text-field>
            <v-text-field label="Kodeord*" v-model="password" type="password" required></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field label="Fornavn*" v-model="firstname" type="name" required></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Efternavn*" v-model="lastname" type="name" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Mobil*" v-model="mobile" type="mobile" onKeyPress="if(this.value.length==8) return false;" maxlength="8" required></v-text-field>

            <v-text-field label="Adresse*" v-model="address" type="adress" required></v-text-field>
            <v-text-field label="Adresse" v-model="address2" type="adress"></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field label="Postnr*" v-model="zip" type="number" onKeyPress="if(this.value.length==4) return false;" maxlength="4" required></v-text-field>
          </v-col>
          <v-col cols="9">
            <v-text-field label="By*" v-model="city" type="city" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Firma" v-model="company" type></v-text-field>
            <v-text-field label="CVR" v-model="cvr" type></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="newsletter" label="Ja tak, jeg vil gerne tilmeldes DRYK's Nyhedsbrev (Du modtager det maksimum 1 gang om måneden)" color="success" hide-details></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn dark color="#8DB294" v-on:click="onCreateCustomer">Opret</v-btn>
          </v-col>
        </v-row>
        <small>*Skal udfyldes</small>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import customer from '../mixins/customer';

export default {
  name: 'CreateCustomer',
  mixins: [customer],

  data() {
    return {
      newsletter: false,
      debug: false,
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      mobile: '',
      address: '',
      address2: '',
      zip: '',
      city: '',
      company: '',
      cvr: '',
    };
  },

  mounted: function () {
    this.email = localStorage.getItem('email');

    if (this.debug) {
      this.password = '12345';
      this.firstname = 'Jan';
      this.lastname = 'Hjørdie';
      this.mobile = '28876905';
      this.address = 'Århusvej 69A';
      this.zip = 3000;
      this.city = 'Helsingør';
      this.company = 'Nørdefabrikken';
    }
  },
  methods: {
    cancel: function () {
      this.$emit('onCancel');
    },
    onCreateCustomer: function () {
      var payload = {
        email: this.email,
        password: this.password,
        first_name: this.firstname,
        last_name: this.lastname,
        phone: this.mobile,
        address: this.address,
        address2: this.address2,
        postal_code: this.zip,
        city: this.city,
        company: this.company,
        vat: this.cvr,
      };

      this.createCustomer(payload).then((res) => {
        //  this.$root.$emit('onMessage', 'Du er nu oprettet og kan logge ind med din valgte kode');
        if (this.newsletter) {
          this.addTagToMailChimpUser(payload.email, 'B2C').then(() => {
            this.$emit('onMessage', 'Tilføjet til nyhedsbrev');
            this.addMailChimpUser(payload.email, payload.first_name, payload.last_name).then(() => {});
          });
        }

        this.$router.push('/login');
        res;
      });
    },
  },
};
</script>
