import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderSubscription: null,
    coreDataLoaded: false,
    customer: null,
    loggedIn: false,
    subscriptions: null,
    invoices: null,
    basket: null,
    boxes: null,
    modalVisible: false,
    modalComponent: null,
    previewOrder: false,
    products: null,
    mixProducts: null,
    plans: null,
    onetimePlans: null,
    selectedPlan: null,
    selectedFreight: null,
    selectedFreightPrice: 0,
    freights: null,
    freight: null,
    discountPacks: null,
    selectedDiscountPack: null,
    discountTotal: null,
    total: null,
    liters: null,
    directFreights: null,
    directFreight: null,
    deliveryMethod: null,
    orderDetails: null,
    voucher: null,
    discountShowed: false,
  },
  mutations: {
    showModal(state, componentName) {
      state.modalVisible = true;
      state.modalComponent = componentName;
    },
    hideModal(state) {
      state.modalVisible = false;
    },
    setCoreDataLoaded(state, coreDataLoaded) {
      state.coreDataLoaded = coreDataLoaded
    },
    setOrderSubscription(state, orderSubscription) {
      state.orderSubscription = orderSubscription
    },
    setCustomer(state, customer) {
      state.customer = customer
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn
    },
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions
    },
    setInvoices(state, invoices) {
      state.invoices = invoices
    },
    setBasket(state, basket) {
      state.basket = basket
    },
    setBoxes(state, boxes) {
      state.boxes = boxes
    },
    setProducts(state, products) {
      state.products = products
    },
    setMixProducts(state, mixProducts) {
      state.mixProducts = mixProducts
    },
    setPlans(state, plans) {
      state.plans = plans
    },
    setOnetimePlans(state, onetimePlans) {
      state.onetimePlans = onetimePlans
    },
    setSelectedPlan(state, selectedPlan) {
      state.selectedPlan = selectedPlan
    },
    setFreights(state, freights) {
      state.freights = freights
    },
    setFreight(state, freight) {
      state.freight = freight
    },
    setSelectedFreight(state, selectedFreight) {
      state.selectedFreight = selectedFreight
    },
    setSelectedFreightPrice(state, selectedFreightPrice) {
      state.selectedFreightPrice = selectedFreightPrice
    },
    setDiscountPacks(state, discountPacks) {
      state.discountPacks = discountPacks
    },
    setSelectedDiscountPack(state, selectedDiscountPack) {
      state.selectedDiscountPack = selectedDiscountPack
    },
    setDiscountTotal(state, discountTotal) {
      state.discountTotal = discountTotal
    },
    setTotal(state, total) {
      state.total = total
    },
    setLiters(state, liters) {
      state.liters = liters
    },
    setDirectFreights(state, directFreights) {
      state.directFreights = directFreights
    },
    setDirectFreight(state, directFreight) {
      state.directFreight = directFreight
    },

    setDeliveryMethod(state, deliveryMethod) {
      state.deliveryMethod = deliveryMethod
    },

    setOrderDetails(state, orderDetails) {
      state.orderDetails = orderDetails
    },
    setVoucher(state, voucher) {
      state.voucher = voucher
    },
    setDiscountShowed(state, discountShowed) {
      state.discountShowed = discountShowed
    },



    previewOrder(state, value) {
      state.previewOrder = value;
    },

  },
  getters: {
    customer: state => state.customer,
    loggedIn: state => state.loggedIn,
    coreDataLoaded: state => state.coreDataLoaded,
    orderSubscription: state => state.orderSubscription,
    subscriptions: state => state.subscriptions,
    invoices: state => state.invoices,
    basket: state => state.basket,
    boxes: state => state.boxes,
    previewOrder: state => state.previewOrder,
    products: state => state.products,
    mixProducts: state => state.mixProducts,
    plans: state => state.plans,
    onetimePlans: state => state.onetimePlans,
    selectedPlan: state => state.selectedPlan,
    freights: state => state.freights,
    freight: state => state.freight,
    directFreight: state => state.directFreight,
    selectedFreight: state => state.selectedFreight,
    selectedFreightPrice: state => state.selectedFreightPrice,
    discountPacks: state => state.discountPacks,
    selectedDiscountPack: state => state.selectedDiscountPack,
    discountTotal: state => state.discountTotal,
    total: state => state.total,
    liters: state => state.liters,
    directFreights: state => state.directFreights,
    deliveryMethod: state => state.deliveryMethod,
    orderDetails: state => state.orderDetails,
    voucher: state => state.voucher,
    discountShowed: state => state.discountShowed,


  },
  actions: {},
  modules: {}
})