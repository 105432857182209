<template>
  <v-app>
    <v-app-bar app flat>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img alt="Vuetify Logo" class="shrink mr-2 dryklogo" contain src="/media/logo.png" transition="scale-transition" />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-menu :close-on-content-click="false" :nudge-width="260" offset-y auto v-model="showOrderSnackbar">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="v-btn-icon-orange" v-on="on" v-bind="attrs" :disabled="boxes == 0">
            <span>({{ boxes }})</span>
            <v-icon right>mdi-basket</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-simple-table dense class="pa-2 pt-3">
              <template>
                <tbody v-if="basket != null">
                  <tr v-for="item in basket" :key="item.name">
                    <td class="text-left">{{ item.count }} stk. {{ item.name }}</td>
                    <td class="text-right">{{ (item.count * item.amount) | formatCurrency }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Fragt</td>
                    <td class="text-right">{{ selectedFreightPrice | formatCurrency }}</td>
                  </tr>
                  <tr v-if="selectedDiscountPack != null">
                    <td colspan="2" class="text-right">
                      <span style="font-weight: bold">{{ selectedDiscountPack.description }} rabat aktiveret, du sparer {{ (total - discountTotal) | formatCurrency }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Total</td>
                    <td class="text-right" v-if="selectedDiscountPack == null">{{ total | formatCurrency }}</td>
                    <td class="text-right" v-if="selectedDiscountPack != null">
                      <span style="text-decoration: line-through">{{ total | formatCurrency }}</span>
                      {{ discountTotal | formatCurrency }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn dark color="#8DB294" small v-on:click="onClearBasket" class="white--text mt-2" :disabled="boxes == 0">
              Tøm
              <v-icon dark right>mdi-delete</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn dark color="#8DB294" small v-on:click="goToBasket" class="white--text mt-2" :disabled="boxes == 0">
              Til kurven ({{ liters }} liter Dryk)
              <v-icon dark right>mdi-basket</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-btn dark color="#757575" to="/" text class="hidden-sm-and-downs"> Produkter </v-btn>
      <v-btn dark color="#757575" to="/om-drykboxen" text class="hidden-sm-and-down"> Drykboxen </v-btn>

      <v-menu offset-y auto v-if="loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="v-btn-orange" x-small fab dark v-bind="attrs" v-on="on">
            <v-icon dark>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/abonnement">Abonnement</v-list-item>
          <v-list-item to="/profil">Profil</v-list-item>
          <v-list-item to="/faktura">Faktura</v-list-item>
          <v-list-item to="/levering">Levering</v-list-item>
          <v-list-item v-on:click="logout" v-show="loggedIn">Log ud <v-icon right>mdi-key</v-icon></v-list-item>
        </v-list>
      </v-menu>

      <v-btn dark color="#757575" text v-on:click="showLoginDialog" v-show="!loggedIn" class="hidden-md-and-down">
        Log ind
        <v-icon dark right>mdi-key</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Dryk Shop </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/">
          <v-list-item-content>
            <v-list-item-title>Produkter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/om-drykboxen">
          <v-list-item-content>
            <v-list-item-title>Om Drykboxen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item link to="/abonnement" v-show="loggedIn">
            <v-list-item-content>
              <v-list-item-title>Abonnement</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/profil" v-show="loggedIn">
            <v-list-item-content>
              <v-list-item-title>Profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/faktura" v-show="loggedIn">
            <v-list-item-content>
              <v-list-item-title>Faktura</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/levering" v-show="loggedIn">
            <v-list-item-content>
              <v-list-item-title>Levering</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-on:click="logout" v-show="loggedIn">
            <v-list-item-content>
              <v-list-item-title>Log ud</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-on:click="showLoginDialog" v-show="!loggedIn">
            <v-list-item-content>
              <v-list-item-title>Log ind</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/opret-kunde" v-show="!loggedIn">
            <v-list-item-content>
              <v-list-item-title>Opret kunde</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item link href="https://dryk.dk" target="_blank">
            <v-list-item-content>
              <v-list-item-title>Dryk Hjemmeside</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link href="https://dryk.dk/handelsbetingelser/" target="_blank">
            <v-list-item-content>
              <v-list-item-title>Handelsbetingelser</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link href="https://dryk.dk/persondata-og-cookies/" target="_blank">
            <v-list-item-content>
              <v-list-item-title>Persondatapolitik</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>Version {{ buildVersion }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-item-group>
    </v-navigation-drawer>

    <v-content>
      <v-row justify="center">
        <v-col lg="8" md="10" sm="12" xs="12" class="text-center">
          <router-view />
          <p class="pb-10"></p>
        </v-col>
      </v-row>
      <v-dialog v-model="showNewPassword" persistent max-width="600px" color="#294B43">
        <NewPassword @onCancel="showNewPassword = false" @onNewPasswordCompleted="onNewPasswordCompleted" />
      </v-dialog>
      <v-snackbar v-model="showSnackbar" :multi-line="false" :timeout="snackbarTimeout" dark color="yellow accent-4" center vertical class="text-center black-snack-text">
        {{ snackbarText }}
      </v-snackbar>
      <v-alert type="error" dismissible v-model="showAlert">{{ alertMessage }}</v-alert>
      <v-dialog v-model="dialog.state" transition="dialog-bottom-transition" max-width="400">
        <v-card class="mx-auto">
          <v-card-text class="pa-4">
            <h4>
              {{ dialog.title }}
            </h4>
            <p>
              {{ dialog.message }}
            </p>
          </v-card-text>

          <v-card-actions>
            <v-btn class="v-btn-green" @click="dialog.state = false">
              {{ dialog.button }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-content>
    <v-footer flat dark class="hidden-sm-and-down">
      <v-row justify="center" no-gutters>
        <v-btn href="https://dryk.dk" text link target="_blank" color="white"> Dryk.dk </v-btn>
        <v-btn href="https://dryk.dk/handelsbetingelser/" link target="_blank" text color="white"> Handelsbetingelser </v-btn>
        <v-btn href="https://dryk.dk/persondata-og-cookies/" link target="_blank" text color="white"> Persondatapolitik </v-btn>

        <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="/media/payments.png" transition="scale-transition" width="60" />
        <v-btn text color="white">
          {{ buildVersion }}
        </v-btn>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import NewPassword from './components/NewPassword';
import customer from './mixins/customer';
import actions from './mixins/actions';
import core from './mixins/core';
import coredata from './mixins/coredata';
import shop from './mixins/shop';

/*import {
    bus
} from "./main";
*/
export default {
  name: 'App',
  mixins: [core, coredata, customer, shop, actions],
  components: { NewPassword },

  data: () => ({
    drawer: false,
    basket: null,
    dialog: { state: false, title: '', message: '', button: 'Ok' },
    boxes: 0,
    liters: 0,
    total: 0,
    tab: null,
    items: [
      {
        tab: 'One',
        content: 'Tab 1 Content',
      },
      {
        tab: 'Two',
        content: 'Tab 2 Content',
      },
    ],
    directFreight: 0,
    loggedIn: false,
    selectedFreightPrice: 0,
    discountTotal: 0,
    showBasket: false,
    showLogin: false,
    selectedDiscountPack: false,
    showRotateScreen: false,
    showOrderSnackbar: false,
    showNewPassword: false,
    showActivateAccount: false,
    showCreateCustomer: false,
    snackbarText: '',
    snackbarColor: '#294a42',
    snackbarTimeout: 5000,
    showSnackbar: false,
    alertMessage: '',
    showAlert: false,
    showMyPage: false,
    showSubNav: false,
  }),
  methods: {
    alert(message) {
      this.showAlert = true;
      this.alertMessage = message;
    },
    toast(message, error) {
      this.snackbarTimeout = 3000;

      if (error == true) {
        this.snackbarColor = 'error';
        this.snackbarTimeout = 20000;
      }
      this.showSnackbar = true;
      this.snackbarText = message;
    },
    hideDialogs() {
      this.showLogin = false;
      this.showNewPassword = false;
      this.showBasket = false;
      this.showRotateScreen = false;
      this.showCreateCustomer = false;
      this.showMyPage = false;
      this.showActivateAccount = false;
    },
    showMyPageDialog() {
      this.tracking('MyPage');
      // this.showMyPage = true;
      this.$router.push('/profil');
    },
    showLoginDialog() {
      this.tracking('Login');
      //      this.showLogin = true;
      this.$nextTick(() => {
        this.$router.push('/login');
      });
    },
    showNewPasswordDialog() {
      this.tracking('NewPassword');
      this.showNewPassword = true;
    },
    showRotateScreenDialog() {
      this.showRotateScreen = true;
    },
    hideRotateScreenDialog() {
      console.log('called:hideRotateScreenDialog');
      this.showRotateScreen = false;
    },
    goToBasket() {
      this.tracking('goToBasket');
      this.showOrderSnackbar = false;
      if (this.loggedIn) {
        this.$nextTick(() => {
          this.$router.push('/kurv').catch(() => {});
        });
      } else {
        this.toast('Du skal være logget ind for at kunne fortsætte');
        this.showLoginDialog();
      }
    },
    onClearBasket() {
      this.tracking('ClearBasket');
      var products = this.$store.getters.products;
      products.forEach((product) => {
        product.count = 0;
      });
      this.$store.commit('setProducts', products);
      this.calculate();
      this.showOrderSnackbar = false;
      this.toast('Kurven er nu tom');
      this.$router.push('/').catch(() => {});
    },
    onNewCustomer() {
      this.tracking('NewCustomer');
      this.hideDialogs();
      this.showCreateCustomer = true;
    },
    onCreateCustomerCompleted() {
      this.tracking('CreateCustomerCompleted');
      this.hideDialogs();
      this.showLoginDialog();
      this.toast('Velkommen til Dryk. Du er nu oprettet som kunde.');
      // this.toast(        "Du er nu oprettet som kunde. Der er sendt en aktiveringsemail til dig. Aktiver før du fortsætter");
    },
    onMessage(message, error) {
      this.toast(message, error);
    },
    onCreatCustomerFailed(message) {
      this.tracking('CreateCustomerFailed');
      this.toast(message, true);
    },

    onLoginCompleted(customer) {
      this.tracking('OnLoginCompleted');
      this.currentCustomer = customer;
      this.$store.commit('setLoggedIn', true);

      this.$nextTick(() => {
        if (this.$store.getters.products) {
          var any = false;
          this.$store.getters.products.forEach(function (product) {
            if (product.count > 0) any = true;
          });
          if (any) {
            this.$router.push('/kurv').catch(() => {});
          } else {
            this.$router.push('/abonnement');
          }
        }
      });
      this.toast('Login godkendt');
    },
    showDialog(title, message, button) {
      this.dialog.title = title;
      this.dialog.message = message;
      this.dialog.button = button;
      this.dialog.state = true;
    },
    onLoginFailed() {
      this.tracking('LoginFailed');
      this.showDialog('Login fejl', 'Du har skrevet en forkert email eller kodeord. Hvis du har glemt dit kodeord, så kan du nulstille det', 'Ok');

      //  this.toast(                "Forkert email eller kodeord. Hvis du har glemt dit kodeord, så kan du nulstille det");
      // this.toast("Forkert email eller kodeord. Din konto skal være aktiveret. Se evt. i din spam mappe efter en konto aktiverings email fra os");
    },
    onNewPasswordCompleted() {
      this.tracking('NewPasswordCompleted');
      this.$router.push(this.$route.path);
      this.isLoggedIn = false;
      this.hideDialogs();
      this.showLoginDialog();
      this.toast('Ny kode gemt');
    },
    logout() {
      this.$store.commit('setLoggedIn', false);

      this.clearSession();
      if (this.$route.path != '/') {
        this.$router.push('/');
      }
    },
    onBasketChange() {
      this.basket = this.$store.getters.basket;
      this.boxes = this.$store.getters.boxes;
      this.liters = this.$store.getters.liters;
      this.selectedFreightPrice = this.$store.getters.selectedFreightPrice;
      this.directFreight = this.$store.getters.directFreight;
      this.discountTotal = this.$store.getters.discountTotal;
      this.selectedDiscountPack = this.$store.getters.selectedDiscountPack;
      this.total = this.$store.getters.total;
    },
  },
  created() {
    this.$root.$on('onShowOrderSnackbar', () => {
      // this.showOrderSnackbar = data;
    });
    this.$root.$on('onLoginCompleted', (data) => {
      this.onLoginCompleted(data);
    });

    this.$root.$on('onNewPasswordCompleted', () => {
      this.onNewPasswordCompleted();
    });
    this.$root.$on('onLoginFailed', () => {
      this.onLoginFailed();
    });
    this.$root.$on('onBasketChange', () => {
      this.onBasketChange();
    });
    this.$root.$on('onClearBasket', () => {
      this.onClearBasket();
    });
    this.$root.$on('onMessage', (message) => {
      this.onMessage(message);
    });
    this.$root.$on('onPasswordReset', () => {
      this.showNewPassword = !this.showNewPassword;
    });
  },
  mounted() {
    this.clearSession();
    this.checkActions();

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setBoxes') {
        this.boxes = state.boxes;
      }
      if (mutation.type === 'setBasket') {
        this.basket = state.basket;
      }
      if (mutation.type === 'setLoggedIn') {
        this.loggedIn = state.loggedIn;
      }
    });
  },
};
</script>
