import Vue from 'vue'
import VueRouter from 'vue-router'
import SignupView from '../components/Signup.vue'
import HelloView from '../components/Hello.vue'
import ProfileView from '../components/Profile.vue'
import SubscriptionView from '../components/Subscription.vue'
import SubscriptionsView from '../components/Subscriptions.vue'
import InvoicesView from '../components/Invoices.vue'
import DeliveryView from '../components/Delivery.vue'
import ShopView from '../components/Shop.vue'
import AboutDrykboxView from '../components/AboutDrykbox.vue'
import BasketView from '../components/Basket.vue'
import LoginView from '../components/Login.vue'
import CreateCustomerView from '../components/CreateCustomer.vue'
import CheckoutView from '../components/Checkout.vue'
import OrderConfirmationView from '../components/OrderConfirmation.vue'
import OrderCancelledView from '../components/OrderCancelled.vue'
import NotFoundView from '../components/404.vue'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueRouter)

const routes = [{
    path: '*',
    component: NotFoundView
  }, {
    path: '/signup',
    name: 'Signup',
    component: SignupView
  }, {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutView
  }, {
    path: '/hello',
    name: 'Hello',
    component: HelloView
  }, {
    path: '/profil',
    name: 'Profile',
    component: ProfileView
  }, {
    path: '/faktura',
    name: 'Invoices',
    component: InvoicesView
  }, {
    path: '/levering',
    name: 'Delivery',
    component: DeliveryView
  }, {
    path: '/abonnement',
    name: 'Subscriptions',
    component: SubscriptionsView
  },
  {
    path: '/abonnement/:id',
    name: 'Subscription',
    component: SubscriptionView
  }, {
    path: '/',
    name: 'Shop',
    component: ShopView
  }, {
    path: '/kurv',
    name: 'Basket',
    component: BasketView
  },
  {
    path: '/ordre-bekraeftelse/:id',
    name: 'OrderConfirmation',
    component: OrderConfirmationView
  }, {
    path: '/ordre-afbrudt/:id',
    name: 'OrderCancelled',
    component: OrderCancelledView
  }, {
    path: '/login',
    name: 'Login',
    component: LoginView
  }, {
    path: '/opret-kunde',
    name: 'CreateCustomer',
    component: CreateCustomerView
  }, {
    path: '/om-drykboxen',
    name: 'AboutDrykbox',
    component: AboutDrykboxView
  }
]


const router = new VueRouter({
  routes,
  base: __dirname,
  mode: 'history',
})


// https://www.npmjs.com/package/@gtm-support/vue2-gtm

Vue.use(VueGtm, {
  id: 'GTM-MSV9PTD',
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});


export default router