<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-row>
            <v-col cols="12">
              <h1>Abonnement</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="subscriptions.length == 0">
              <h3>Endnu ingen abonnementer</h3>
            </v-col>
          </v-row>
          <v-row v-if="subscriptions.length != 0">
            <v-col cols="12">
              <v-simple-table light>
                <template v-slot:default>
                  <thead>
                    <tr class="text-left">
                      <th>Abonnement</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th width="120">Start</th>
                      <th>Næste abonnement fornyelse</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="subscription in subscriptions" :key="subscription.handle" class="text-left">
                      <td>
                        {{ subscription.handle }}
                      </td>
                      <td>
                        {{ subscription.description }}
                      </td>
                      <td>{{ subscription | subscriptionState }}</td>
                      <td>{{ subscription.first_period_start | moment('DD-MM-YYYY') }}</td>

                      <td>
                        <span v-show="subscription.renewing"> {{ subscription.next_period_start | moment('DD-MM-YYYY') }}</span
                        ><span v-show="!subscription.renewing">Ingen</span>
                      </td>
                      <td>
                        <v-btn class="v-btn-green" :to="'/abonnement/' + subscription.handle">Vis<span v-if="(!subscription.is_cancelled && subscription.renewing) || subscription.state == 'pending'">/Rediger</span></v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-card-text>
            <a style="color: #fff" v-bind:href="'mailto:support@dryk.dk'">Spørgsmål til dit abonnement, skriv til support@dryk.dk</a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import customer from '../mixins/customer';
import core from '../mixins/core';
import shipment from '../mixins/shipment';

export default {
  name: 'Subscriptions',
  mixins: [core, shipment, customer],
  props: ['basket'],
  data() {
    return {
      subscriptions: [],
    };
  },

  mounted: function () {
    if (!this.$store.getters.loggedIn) this.$router.push('/');

    this.getSubscriptions(this.$store.getters.customer.handle).then(() => {
      this.subscriptions = this.$store.getters.subscriptions;
    });

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setSubscriptions') {
        this.subscriptions = state.subscriptions;
      }
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },

  methods: {},
};
</script>
