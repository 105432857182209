<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <h1>Betaling af abonnement {{ subscriptionId }} fejlede eller blev afbrudt</h1>
        <p class="pt-5">Abonnementet er oprettet, men venter blot på betalingen.</p>
        <p>Hvis du afbrød betalingen fordi du glemte noget, så kan du bare rette indholdet af abonnementet og derefter tilknytte dit betalingskort</p>
        <p>Du kan gå direkte til dit abonnement her</p>
        <p>
          <v-btn class="v-btn-green" text :to="'/abonnement/' + $route.params.id">Gå til abonnement {{ $route.params.id }}</v-btn>
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center">
        <p>
          <a href="https://dk.trustpilot.com/review/dryk.dk" target="_blank" class="trustpilot"><img alt="" title="Trustpilot Dryk" src="/media/trustpilot.jpg" width="350" /></a>
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="8" md="10" sm="12" class="text-center"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'OrderCancelled',
  data() {
    return {
      orderDetails: null,
      subscriptionId: null,
    };
  },
  mounted: function () {
    this.subscriptionId = this.$route.params.id;
    this.orderDetails = this.$store.getters.orderDetails;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
};
</script>
