import axios from 'axios';
//import retryInterceptor from 'axios-retry-interceptor';
import core from "./core";
import store from './../store'
import * as Sentry from '@sentry/browser';

/*retryInterceptor(axios, {
    maxAttempts: 10,
    waitTime: 1000
});
*/

export default {
    store,
    mixins: [core],
    methods: {
        clearSession: function () {
            localStorage.removeItem("resetToken");
            localStorage.removeItem("token");
            localStorage.removeItem("loginToken");
            localStorage.removeItem("verificationToken");
            localStorage.removeItem("customer");
            localStorage.removeItem("customerHandle");
            localStorage.removeItem("selectedFreight");

            return true;
        },

        getCustomer: function (email) {

            return axios.get(this.baseFunctionUrl + "GetCustomerByEmail/" + email).then((res) => {
                var customer = res.data;
                this.currentCustomer = customer;
                window.fcWidget.setExternalId(this.currentCustomer.handle);
                window.fcWidget.user.setFirstName(this.currentCustomer.first_name);
                window.fcWidget.user.setPhone(this.currentCustomer.phone);
                store.commit('setCustomer', customer);

                return customer;
            })
        },
        updateCustomer: function (payload) {

            return axios.post(this.baseFunctionUrl + "UpdateCustomer", payload).then((res) => {
                var customer = res.data;

                store.commit('setCustomer', customer);

                return customer;
            })
        },

        getSubscription: function (handle) {
            return axios
                .get(this.baseFunctionUrl + "GetSubscription/" + handle)
                .then(function (subscription) {
                    return subscription;
                });
        },
        getSubscriptionAddons: function (handle) {
            return axios
                .get(this.baseFunctionUrl + "GetSubscriptionAddons/" + handle)
                .then(function (subscription) {
                    return subscription;
                });
        },
        setSubscriptionRenewalDate: function (handle, date) {
            return axios
                .post(this.baseFunctionUrl + "ChangeNextRenewal/" + handle + "/" + this.$moment(date).format("YYYY-MM-DD"))
                .then(function (subscription) {
                    return subscription;
                });
        },
        updateSubscriptionAddons: function (handle, payload) {
            return axios
                .put(this.baseFunctionUrl + "UpdateSubscription/" + handle, payload)
                .then(function (subscription) {
                    return subscription;
                });
        },
        cancelSubscriptionChange: function (handle) {
            return axios
                .put(this.baseFunctionUrl + "CancelSubscriptionChange/" + handle)
                .then(function (subscription) {
                    return subscription;
                });
        },
        cancelSubscription: function (handle) {
            return axios
                .post(this.baseFunctionUrl + "CancelSubscription/" + handle)
                .then(function (subscription) {
                    return subscription;
                });
        },
        getSubscriptions: function (handle) {
            return axios
                .get(this.baseFunctionUrl + "GetCustomerSubscriptions/" + handle)
                .then(function (subscriptions) {
                    subscriptions = subscriptions.data.content.length > 0 ? subscriptions.data.content : [];
                    store.commit('setSubscriptions', subscriptions);
                    return subscriptions;
                });
        },
        getInvoices: function (handle) {

            return axios
                .get(this.baseFunctionUrl + "GetCustomerInvoices/" + handle)
                .then(function (invoices) {
                    invoices = invoices.data.Count > 0 ? invoices.data.Content : [];
                    store.commit('setInvoices', invoices);
                    return invoices;
                });
        },
        getCustomerHandle: function (email) {

            return axios
                .get(this.baseFunctionUrl + "GetCustomerHandle/" + email)
                .then(function (res) {
                    localStorage.setItem("customerHandle", res.data.Value);
                    return res.data.Value;
                });
        },
        prepareOrderPayload: function (basket, token, voucher) {
            var getters = store.getters;
            if (getters) {
                console.log(getters);
            }

            var customer = store.getters.customer;
            var payload = {
                customer: customer.handle,
                plan: store.getters.selectedPlan != null ? store.getters.selectedPlan : 'onetime-plan-fa3af',
                add_ons: [],
                coupon_codes: null,
                signup_method: "source",
                source: token

            };

            basket.forEach(function (res) {
                var addon = {
                    handle: res.handle,
                    add_on: res.handle,
                    quantity: res.count,
                    amount: (100 * res.amount).toFixed(0) // res.meta.liters > 0 ? basket.selectedDiscountPack != null ? basket.selectedDiscountPack.amount * 100 / basket.selectedDiscountPack.quantity : 100 * res.amount : 100 * res.amount
                };
                payload.add_ons.push(addon)
            });

            var selectedFreight = this.$store.getters.selectedFreight;
            var directFreight = this.$store.getters.directFreight;
            var selectedDiscountPack = this.$store.getters.selectedDiscountPack;
            var deliveryMethod = this.$store.getters.deliveryMethod;

            var freight = {
                handle: selectedFreight.handle,
                add_on: selectedFreight.handle,
                amount: (100 * selectedFreight.amount).toFixed(0)
            };
            if (selectedDiscountPack == null)
                payload.add_ons.push(freight);

            if (deliveryMethod == 'direct') { //JHJ
                freight = {
                    handle: directFreight.handle,
                    add_on: directFreight.handle,
                    amount: (100 * directFreight.amount).toFixed(0)
                };

                payload.add_ons.push(freight);
            }

            if (selectedDiscountPack != null && !selectedDiscountPack.combineWithDiscounts)
                voucher = null;

            if (voucher != null && voucher.length > 2) {
                payload.coupon_codes = [];
                payload.coupon_codes.push(voucher);
            }
            return payload;
        },
        sendOrder: function (basket, token, voucher) {
            var payload = this.prepareOrderPayload(basket, token, voucher);
            return axios.post(this.baseFunctionUrl + "CreateSubscription", payload).then(function (res) {
                var subscription = res.data;
                return subscription;
            })

        },
        prepareOrder: function (basket, token, voucher) {
            var payload = this.prepareOrderPayload(basket, token, voucher);
            return axios.post(this.baseFunctionUrl + "CreateSubscriptionPrepare", payload).then(function (res) {
                var subscription = res.data;
                return subscription;
            })

        },
        previewOrder: function (basket, token, voucher) {

            var payload = this.prepareOrderPayload(basket, token, voucher);
            payload.signup_method = "link";
            console.log(payload);
            return axios.post(this.baseFunctionUrl + "CreateSubscriptionPreview", payload).then(function (res) {
                var subscription = res.data;
                return subscription;
            })
        },
        createSubscriptionChargeSession: function (handle) {
            return axios.post(this.baseFunctionUrl + "CreateSubscriptionChargeSession/" + handle).then(function (res) {
                var session = res.data;
                return session;
            })
        },
        resetPassword: function (email) {

            return axios.post(this.baseFunctionUrl + "SendPasswordResetEmail/", email, ).then(function (result) {
                console.log("Kode nulstil link er sendt til din email " + email);
                return result;
            })
        },

        createCustomer: function (payload) {

            var loginEmailUsed = false;
            localStorage.setItem("email", payload.email);
            return axios.post(this.baseFunctionUrl + "IsEmailUsed", payload.email).then(res => {
                loginEmailUsed = res.data;
                if (!loginEmailUsed) {
                    return axios.post(this.baseFunctionUrl + "CreateCustomer/", payload).then(res => {

                        if (res.data.email == payload.email) {
                            this.$root.$emit("onCreateCustomerCompleted");

                            return "ok";
                        } else {
                            this.$root.$emit("onMessage", "Der skete en fejl. Kontakt support@dryk.dk for hjælp");
                            return;
                        }
                    })
                } else {
                    this.$root.$emit("onMessage", "Der findes allerede en bruger med den email");
                    return;
                }
            })

        },

        setPassword: function (email, password) {
            var payload = {
                email: email,
                password: password,
                verificationToken: localStorage.getItem("resetToken")
            };
            return axios.post(this.baseFunctionUrl + "PasswordReset/", payload).then(result => {
                if (result.data == true) {
                    this.$root.$emit("onNewPasswordCompleted");
                    return true;
                }
                return false;
            })
        },

        addTagToMailChimpUser: function (email, tag) {
            return axios.get(this.baseFunctionUrl + "AddTagToMailChimpUser?email=" + email + "&tag=" + tag + "&listId=a88ee40d6b").then(result => {
                if (result.data == true) {
                    return true;
                }
                return false;
            })
        },
        addMailChimpUser: function (email, firstname, lastname) {
            return axios.get(this.baseFunctionUrl + "AddMailChimpUser?email=" + email + "&firstname=" + firstname + "&lastname=" + lastname + "&listId=a88ee40d6b").then(result => {
                if (result.data == true) {
                    return true;
                }
                return false;
            })
        },

        customerLogin: function (email, password) {

            Sentry.configureScope(function (scope) {
                scope.setUser({
                    "email": email,
                    'username': email
                });
            });


            /* 
                Sentry.configureScope(function (scope) {
                    scope.setTag("page", "login");
                });
                Sentry.configureScope(function (scope) {
                    scope.setExtra("login", "");
                });
            */


            window.fcWidget.user.setEmail(email);

            localStorage.setItem("email", email);
            return axios.post(this.baseFunctionUrl + "IsEmailUsed/", email).then(result => {
                var loginEmailUsed = result.data;
                if (loginEmailUsed) {

                    return axios.post(this.baseFunctionUrl + "Login/", {
                        email: email,
                        password: password
                    }).then(function (result) {
                        if (result.status == 200) {
                            localStorage.setItem("loginToken", result.data);
                            return true;
                        }
                        return false;
                    }).catch(function () {
                        return false;
                    })
                }
            })
        }
    }
}