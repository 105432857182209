<template>
  <v-card class="pa-5">
    <v-card-title>
      <span class="headline">Nyt kodeord</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Kodeord*" v-model="password" type="password" required></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <small>*Skal udfyldes</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="#8DB294" v-on:click="newPassword">Gem ny kode</v-btn>
      <v-btn dark color="#8DB294" v-on:click="cancel">Fortryd</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import customer from '../mixins/customer';

export default {
  name: 'NewPassword',
  mixins: [customer],

  data() {
    return {
      email: '',
      password: '',
    };
  },
  mounted: function () {
    this.email = localStorage.getItem('email');
  },
  methods: {
    cancel: function () {
      this.$emit('onCancel');
    },
    newPassword: function () {
      this.setPassword(this.email, this.password).then((res) => res);
    },
  },
};
</script>
