import core from "./core";
import store from '../store'

export default {
    store,
    mixins: [core],
    methods: {
        calculate: function () {

            var products = Array.isArray(this.$store.getters.products) ? this.$store.getters.products : null;
            var plans = this.$store.getters.plans ? this.$store.getters.plans : null;
            var freights = this.$store.getters.freights;
            var directFreights = this.$store.getters.directFreights ? this.$store.getters.directFreights : 0;
            var selectedFreightPrice = 0;
            var selectedFreight = null;
            var selectedDiscountPack = null;
            var discountPacks = this.$store.getters.discountPacks;
            var discountTotal = 0;
            var total = 0;
            var liters = 0;
            var boxes = 0;

            var basket = products.filter(function (product) {
                return product.count > 0;
            });

            basket.forEach(function (item) {
                total += item.amount * item.count;
                liters += item.count * item.meta.liters;
                if (item.meta.liters > 0)
                    boxes += item.count;
            });

            // var selectedPlanDetails = plans.find((plan) => plan.handle == plan);

            freights.forEach(function (freight) {
                if (freight.meta.liters == liters) {
                    selectedFreight = freight;
                    selectedFreightPrice = freight.amount;
                }
            });

            discountPacks.forEach(function (item) {
                if (item.active ) {
                    selectedDiscountPack = item;
                }
            });
            
            total += selectedFreightPrice;
             

            if (selectedDiscountPack != null)
            {
                if (total < selectedDiscountPack.amount) {
                    selectedDiscountPack = null;
                }
            else
                {
                    discountTotal = total - selectedFreightPrice;

                }
            }

            /*

            discountPacks.forEach(function (item) {
                if (boxes >= item.quantity && item.active && item.allowedAddOns == null) {
                    selectedDiscountPack = item;
                }
            });


            basket.forEach(function (item) {

                if (item.handle.includes("close-to-expiration")) {
                    discountPacks.forEach(function (discount) {
                        if (boxes >= discount.quantity && discount.active && discount.allowedAddOns != null && item.handle.includes(discount.allowedAddOns)) {
                            selectedDiscountPack = discount;
                        }
                    });
                }
            });

            if (selectedDiscountPack != null) {
                discountTotal = selectedDiscountPack.inclusiveFreight ?
                    total - selectedFreightPrice :
                    selectedDiscountPack.amount + selectedFreight;
            }

            */

            var selectedPlan = plans.find(plan => plan.handle == selectedPlan);

            this.$store.commit('setBasket', basket);
            this.$store.commit('setSelectedPlan', selectedPlan);
            this.$store.commit('setBoxes', boxes);
            this.$store.commit('setTotal', total);
            this.$store.commit('setLiters', liters);
            this.$store.commit('setDiscountTotal', discountTotal);
            this.$store.commit("setSelectedFreight", selectedFreight);
            this.$store.commit("setSelectedFreightPrice", selectedFreightPrice);
            this.$store.commit('setDirectFreight', directFreights[0]);
            this.$store.commit("setSelectedDiscountPack", selectedDiscountPack);

            this.$root.$emit('onBasketChange');
        },

    }
}