<template>
  <div>
    <v-row justify="center" align="center">
      <v-col class="text-left d-flex" lg="8" md="8" sm="12" xs="12" style="flex-direction:column">
        <v-card flat>
          <div class="d-flex justify-center">
            <div class="pa-0">
              <v-img width="100%" max-width="150px" class="justify-center" src="/media/drykbox.png" style="background-color:none;" />
            </div>
          </div>
        </v-card>
        <v-card flat>
          <v-card-text class="text-center pa-4">
            <h1>Om Dryk boxen</h1>
            <p class="mt-5">
              Dryk leveres i 6 liters Drykboxe.
            </p>
            <p>
              Fuld tilfredshed eller pengene' tilbage garanti.
            </p>
            <p>
              Minimum 3 måneders holdbarhed. Med mindre andet er informeret ved købet.
            </p>
          </v-card-text>
          <v-card-text class="text-center pa-4">
            <h2>Levering</h2>
            <p>
              Du skal være opmærksom på, at selvom din ordre er pakket, så henter vores vognmand kun varer hos os 2 dage om ugen for at mindste vores CO2 aftryk.
            </p>
            <p>
              Når du bestiller, vil du få vist hvilken dag du kan forvente at modtage din Dryk pakkke
            </p>
          </v-card-text>
        </v-card>
        <v-card flat class="center">
          <a href="https://dk.trustpilot.com/review/dryk.dk" target="_blank" class="trustpilot"><img alt="" width="350" title="Trustpilot Dryk" src="/media/trustpilot.jpg"/></a>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'AboutDrykbox',
};
</script>
