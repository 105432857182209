<template>
  <div>
    <!-- 
    <v-row>
      <v-col>
        <v-banner style="background-color:#ff0;text-align:center;padding:auto;" elevation="6">
          <div style="width:100%;text-align:center;padding:auto;">
            Vi forventer desværre forlængede leveringstider for tiden, da cykelløbet Tour de France er i Danmark. Vi beklager ulejligheden.
          </div>
        </v-banner>
      </v-col>
    </v-row>
    -->
    <v-row>
      <v-col v-if="products.length == 0">
        <v-progress-circular :size="100" :width="5" color="#8DB294" indeterminate>Indlæser Dryk</v-progress-circular>
      </v-col>
      <v-col v-for="product in products" :key="product.name" lg="3" md="4" sm="6" xs="12" class="d-flex" style="flex-direction: column">
        <v-card class="mx-auto flex-grow-1 my-1" background-color="#fff" v-bind:disabled="disableProduct(product)" flat elevation="0" style="width: 100%">
          <v-card-text style="width: 100%">
            <div style="width: 100%">
              <!--  <v-img width="100%" position="center" contain v-bind:src="product.image" />-->
              <div style="margin-left: auto; margin-right: auto; width: 220px">
                <fabric-canvas :id="'canvas-' + product.handle" :width="220" :height="260" :selectable="false">
                  <fabric-background :id="'image-' + product.handle" :url="product.image" :selectable="false"></fabric-background>
                  <fabric-circle v-if="product.meta.show_badge || product.meta.show_badge_text" :id="'circle-' + product.handle" :radius="35" fill="rgba(255, 255, 255, 0.8)" stroke="#8DB294" :stroke-width="2" :left="125" :top="95" :selectable="false"></fabric-circle>
                  <fabric-text
                    v-if="product.meta.show_badge && !product.meta.show_badge_text"
                    :id="'text-' + product.handle"
                    :text="(product.amount / product.meta.liters) | cleanCurrency"
                    fill="#8DB294"
                    font-family="Roboto, sans-serif"
                    font-weight="bold"
                    :font-size="20"
                    :origin-x="'center'"
                    :origin-y="'center'"
                    :text-align="'center'"
                    :width="70"
                    :top="130"
                    :left="160"
                    :selectable="false"
                  ></fabric-text>
                  <fabric-text
                    v-if="product.meta.show_badge_text"
                    :id="'text2-' + product.handle"
                    :text="product.meta.badge_text"
                    fill="#8DB294"
                    font-family="Roboto, sans-serif"
                    font-weight="bold"
                    :font-size="20"
                    :origin-x="'center'"
                    :origin-y="'center'"
                    :text-align="'center'"
                    :width="70"
                    :top="130"
                    :left="160"
                    :selectable="false"
                  ></fabric-text>
                </fabric-canvas>
              </div>
            </div>
          </v-card-text>
          <v-card-text>
            <h3 class="text-center" style="width: 100%">
              {{ product.name }}
            </h3>
          </v-card-text>

          <v-card-text style="height: 70px; position: relative" class="hidden-sm-and-down">
            <div style="margin: 0; position: absolute; top: 50%; transform: translateY(-50%); text-align: center; width: 100%">
              {{ product.description }}
            </div>
          </v-card-text>
          <v-card-text class="hidden-md-and-up">
            {{ product.description }}
          </v-card-text>
          <v-card-text>
            <b>{{ product.amount | formatCurrency }}</b>
          </v-card-text>
          <v-card-actions>
            <p class="text-center" style="width: 100%">
              <v-btn class="mx-2" dark color="#8DB294" v-on:click="increment(product)" elevation="0" :disabled="product.count >= product.meta.purchase_limit"> Tilføj til kurv </v-btn>
            </p>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col lg="6" md="8" sm="12" class="text-center">
        <a href="https://dk.trustpilot.com/review/dryk.dk" target="_blank" class="trustpilot"><img title="Trustpilot Dryk" src="/media/trustpilot.jpg" width="350" /></a>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar" :timeout="5000" dark color="yellow accent-4" center vertical class="text-center black-snack-text"> {{ snackbarText }} </v-snackbar>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="showDiscount">
      <v-card>
        <v-toolbar color="#8DB294" dark>Rabat</v-toolbar>
        <v-card-text>
          <div class="text-h1 pa-10 text-center">
            <h2>{{ snackbarText }}</h2>

            <p class="pt-5">Dialogen lukker om 5 sek.</p>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="showDiscount = false">Luk</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--
        <v-snackbar v-model="showOrderSnackbarx" dark color="#8DB294" :bottom=true :vertical=true :timeout="2500">
            <v-card color="#8DB294" flat>
                <v-row align="center">
                    <v-col align="center">

                        <v-simple-table dense>
                            <template v-slot:default>
                               
                                <tbody>
                                    <tr v-for="item in basket" :key="item.name">
                                        <td class="text-left">{{ item.count }} {{ item.name }}</td>
                                        <td class="text-right">{{ item.count * item.amount| formatCurrency }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Fragt</td>
                                        <td class="text-right">{{freight| formatCurrency}}</td>
                                    </tr>
                                    <tr v-if="selectedDiscountPack != null">

                                        <td colspan="2" class="text-right">
                                            <span style="font-weight: bold;">{{selectedDiscountPack.description}} rabat aktiveret, du sparer {{ (total-discountTotal) | formatCurrency}}</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Total</td>
                                        <td class="text-right" v-if="selectedDiscountPack == null">{{total | formatCurrency}}</td>
                                        <td class="text-right" v-if="selectedDiscountPack != null">

                                            <span style="text-decoration: line-through;">{{total | formatCurrency}}</span>
                                            {{discountTotal | formatCurrency}}
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-btn color="white" small v-on:click="showBasketDialog" class="black--text mt-2" :disabled="boxes == 0">
                            Til kassen ({{boxes}} drykbox)
                            <v-icon dark right>mdi-basket</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-btn dark absolute x-small top right fab color="rgb(41, 75, 67)" style="margin-right:-30px;margin-top:1px;" @click="showOrderSnackbar = false">
                    <v-icon dark>mdi-close-circle</v-icon>
                </v-btn>

            </v-card>
        </v-snackbar>
        -->
  </div>
</template>

<script>
import { setTimeout } from 'timers';
import vueFabricWrapper from 'vue-fabric-wrapper';
import core from '../mixins/core';
import shop from '../mixins/shop';

export default {
  name: 'Shop',
  mixins: [core, shop],

  components: {
    FabricCanvas: vueFabricWrapper.FabricCanvas,
    FabricCircle: vueFabricWrapper.FabricCircle,
    FabricBackground: vueFabricWrapper.FabricBackgroundImage,
    //   FabricImageFromURL: vueFabricWrapper.FabricImageFromURL
  },
  data() {
    return {
      deliveryType: 'onetime',
      progress: 0,
      showOrderSnackbar: false,
      snackbarText: '',
      showSnackbar: false,
      showDiscount: false,
      liters: 0,
      freight: 0,
      total: 0,
      discountTotal: 0,
      plans: [],
      products: [],
      freights: [],
      directFreights: [],
      discountPacks: [],
      selectedFreight: null,
      selectedPlan: 'plan-2d2fb',
      basket: 0,
      selectedDiscountPack: null,
      selectedPlanDetails: null,
    };
  },
  methods: {
    disableProduct: function (product) {
      if (product.meta.active == false) return true;
      return false;

      /*if (this.selectedPlanDetails.handle.startsWith('onetime') && product.meta.type == 'mix') return false;
                        if (this.selectedPlanDetails.handle.startsWith('plan') && product.meta.type == 'product') return false;

                        return true;
            */
    },
    showBasketDialog: function () {
      this.$root.$emit('onShowBasketDialog');
      this.showOrderSnackbar = false;
    },

    increment: function (product) {
      var max = 10;

      if (this.liters < max) {
        product.count++;
        this.snackbarText = product.name + ' tilføjet til kurven';
        this.showSnackbar = true;
      } else {
        this.snackbarText = 'Du kan max købe ' + max + ' Drykboxe (' + max * 6 + ' liter) af gangen';
        this.showSnackbar = true;
      }

      this.$store.commit('setProducts', this.products);
      this.calculate();
    },
    decrement: function (product) {
      product.count > 0 && product.count--;
      this.calculate();
    },
    clearProductCount: function () {
      this.products.forEach((product) => {
        product.count = 0;
      });
    },
    loadInitialData: function () {
      this.progress = 20;

      this.plans = this.$store.getters.plans;

      this.selectedPlan = 'onetime-plan-fa3af';

      this.progress = 40;
      this.directFreights = this.$store.getters.directFreights;
      this.progress = 60;
      this.freights = this.$store.getters.freights;
      this.progress = 80;

      this.products = this.$store.getters.products;

      this.snackbarText = 'Smagekasser og tilbudsvarer kan kun bestilles med engangslevering, alle andre varer købes med abonnement (Se leveringsfrekvens)';
      this.showSnackbar = false;

      var discountPacksMessage = [];
      this.discountPacks = this.$store.getters.discountPacks;

      this.discountPacks.forEach((discountPack) => {
        if (discountPack.active) discountPacksMessage.push(discountPack.description);
      });
      if (discountPacksMessage.length > 0) {
        this.snackbarText = discountPacksMessage[0];

        if (!this.$store.getters.discountShowed && this.$route.query.action != 'resetpassword') {
          this.$store.commit('setDiscountShowed', true);
          this.showDiscount = true;
          setTimeout(() => {
            this.showDiscount = false;
          }, 5000);
        } else this.showSnackbar = true;
      }
      this.progress = 100;
      // this.calculate();

      /*
      this.getPlans().then(e => (this.plans = e));
      this.getProducts().then(e => (this.products = e));
      this.getFreights().then(e => (this.freights = e));
      this.getDirectFreights().then(e => (this.directFreights = e));
  */
    },
  },
  created: function () {
    this.$on('onCoreDataLoaded', () => {
      if (this.$route.query.action == 'onetime-delivery') {
        this.selectedPlan = 'onetime-plan-fa3af';
      }
    });

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setCoreDataLoaded') {
        if (state.coreDataLoaded) this.loadInitialData();
      }
    });
  },
  mounted: function () {
    this.tracking('/');
    if (this.$store.getters.coreDataLoaded) {
      this.loadInitialData();
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  },
  watch: {
    selectedPlan: function (/*newValue, oldValue*/) {
      //  var changePlan = (newValue.startsWith('plan') && oldValue.startsWith('onetime') || newValue.startsWith('onetime') && oldValue.startsWith('plan'));
      //this.calculate();
    },
  },
};
</script>
