import axios from 'axios';
import retryInterceptor from 'axios-retry-interceptor';
import core from './../mixins/core';
import * as Sentry from '@sentry/browser';
import store from '../store'

retryInterceptor(axios, {
    maxAttempts: 10,
    waitTime: 1000
});

export default {
    store,
    mixins: [core],
    data: function () {
        return {
            coreData: null,
        }
    },
    created: function () {
        Sentry.setTag("buildVersion", this.buildVersion);
        this.getCoreData().then((res) => {
            var coreDataLoaded = this.$store.getters.coreDataLoaded;

            if (!coreDataLoaded) {
                this.coreData = res;
                var plans = [];

                res.onetimePlans.forEach((plan) => {
                    plans.push(plan);
                });
                res.plans.forEach((plan) => {
                    plans.push(plan);
                });

                this.$store.commit("setPlans", plans);
                this.$store.commit("setOnetimePlans", res.onetimePlans);
                this.$store.commit("setDirectFreights", res.directFreights);
                this.$store.commit("setFreights", res.freights);
                this.$store.commit("setDiscountPacks", res.discountPacks);
                this.$store.commit("setDeliveryMethod", "droppoint");
                this.$store.commit("setCoreDataLoaded", true);

                this.$emit("onCoreDataLoaded");
            }
        }).catch((error) => {
            Sentry.setExtra('data', JSON.stringify(error, undefined, 2));
            Sentry.captureMessage('after coredata not loaded', 'error');
        });
    },

    methods: {
        compare: function (a, b) {
            if (a.meta.sort_order < b.meta.sort_order) return -1;
            if (a.meta.sort_order > b.meta.sort_order) return 1;
            return 0;
        },
        getCoreData: function () {
            return axios
                .get(this.baseFunctionUrl + "GetCoreData").then(response => {
                    var result = response.data;

                    var _products = [];
                    result.products.forEach(function (product) {
                        product.show = false;
                        product.count = 0;
                        product.image = "/media/box/" + product.meta.active_image_v2;

                        if (!product.meta.active)
                            product.image = "/media/box/" + product.meta.inactive_image;
                        _products.push(product)
                    });
                    var _mixProducts = [];
                    result.mixProducts.forEach(function (product) {
                        product.show = false;
                        product.count = 0;
                        product.image = "/media/box/" + product.meta.active_image;

                        if (!product.meta.active)
                            product.image = "/media/box/" + product.meta.inactive_image;
                        _mixProducts.push(product)
                    });

                    _mixProducts.forEach((product) => {
                        _products.push(product);
                    });
                    _products = _products.sort(this.compare);
                    this.$store.commit("setProducts", _products);

                    return result;
                }).catch((error) => {
                    Sentry.setExtra('data', JSON.stringify(error, undefined, 2));
                    Sentry.captureMessage('coredata not loaded', 'error');

                    //   window.location.reload();
                });
        },


    }
};